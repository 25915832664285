import Success from 'components/shared/Success';
import Error from 'components/shared/Error';
import Card from 'components/shared/Card';
import styled from 'styled-components';

const Item = styled.div`
	margin-bottom: 1rem;

	&:last-of-type {
		margin-bottom: 0;
	}
`;
const Forms = ({ page }) => {
	// Destructure data
	const { forms } = { ...page };

	// Render functions
	const renderTitle = title => {
		if (title) {
			return <Success content={title} />;
		} else {
			return <Error content={'Not found'} popover={'Form should have a unique, descriptive name.'} />;
		}
	};

	const renderStatus = recipient => {
		// Form recipient
		const appfolio_defaults = ['O0wrseIfFLtEF5OysN6MiTkRDTmKYrYZzqwoleJ7a0PnZvgTXmfYrnLGKsXSZRIUXMLsUZGGBAA=', 'O0wrseIfFLuf2V43hAh7Gm4tBW1vEegFObcMZ5LnzewlEoDnLLBYHA9uc8uEKPjGsOyhCPFr0d8='];

		if (appfolio_defaults.includes(recipient)) {
			return <Error content={'Recipient has not been specified'} popover={'Ensure the form has a recipient email address'} />;
		} else {
			return <Success content={'Recipient has been specified'} />;
		}
	};

	return (
		<>
			{forms && forms.length > 0 && (
				<Card icon={'fas fa-database'} title={'Contact Forms'}>
					{forms.map((form, index) => (
						<Item key={index}>
							<p className='text-secondary m-0'>
								Title: <span className='text-dark'>{renderTitle(form.form_title)}</span>
							</p>
							<p className='text-secondary'>
								Status: <span className='text-dark'>{renderStatus(form.form_recipient)}</span>
							</p>
						</Item>
					))}
				</Card>
			)}
		</>
	);
};

export default Forms;
