import PageHeader from 'components/shared/PageHeader';
import SiteIdInput from 'components/shared/SiteIdInput';
import { useNavigate } from 'react-router-dom';

const Listings = () => {
	// Hooks
	const navigate = useNavigate();

	// Handlers
	const handleSubmit = ({ site_id }) => {
		navigate(`/listings/${site_id}`);
	};

	return (
		<>
			<PageHeader heading='Listings Data' subheading="Inspect the health of a site's listings by comparing site collection & API data." />
			<SiteIdInput submitFunction={handleSubmit} apmOnly={true} />
		</>
	);
};

export default Listings;
