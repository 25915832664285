import PublishInfo from 'components/shared/PublishInfo';
import TemplateInfo from 'components/shared/TemplateInfo';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import { useLogin } from 'utils/UserContext';

const Component = styled.div`
	container-type: inline-size;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@container (max-width: 40rem) {
		grid-template-columns: 100%;
	}
`;

const Image = styled.div`
	background: var(--lightest);
	display: grid;
	place-items: center;
`;

const Thumbnail = styled.img`
	object-fit: cover;
	object-position: center top;
	width: 100%;
	max-height: 30rem;
`;

const Placeholder = styled.i`
	font-size: 5rem;
	color: var(--bs-secondary);
`;

const Content = styled.div`
	padding: 1.5rem;
	display: grid;
	gap: 0.75rem;
	align-content: start;
`;

export const Details = ({ site_details, mutate }) => {
	// Props
	const { site_domain, business_vertical, site_name, publish_status, last_published_date, thumbnail_url, preview_site_url, template_id, allow_automated_qa } = { ...site_details };

	// Hooks
	const { user } = useLogin();
	const navigate = useNavigate();
	const { setMessage } = useMessage();

	// Destructure site details
	const site_url = publish_status === 'PUBLISHED' ? `http://${site_domain}` : preview_site_url?.replace('preview', 'site');

	// Handlers
	const handleSubmit = async () => {
		const user_email = user.email;
		const status = 'Queued';
		const auto_created = false;

		// Structure form body
		const body = { site_id: site_name, site_url, business_vertical, user_email, status, auto_created };

		// Add job to API
		try {
			const { id } = await fetcher('/api/v1/jobs/new', { method: 'POST', body: JSON.stringify(body) });
			navigate(`/job/${id}`);
		} catch (error) {
			setMessage({ variant: 'danger', text: 'There was an error creating this job.' });
		}
	};

	const handleAutoQA = async allow_automated_qa => {
		try {
			await fetcher(`/api/v1/sites/automated_qa/${business_vertical}/${site_name}`, { method: 'POST', body: JSON.stringify({ allow_automated_qa }) });
			await mutate();
			setMessage({ variant: 'warning', text: `Automated QA for ${business_vertical} site ${site_name} has been ${allow_automated_qa ? 'enabled' : 'disabled'}` });
		} catch (error) {
			setMessage({ variant: 'danger', text: error.message });
		}
	};

	return (
		<Component>
			<Wrapper>
				<Image>{thumbnail_url ? <Thumbnail src={thumbnail_url} alt={site_url} /> : <Placeholder className='fas fa-file-image' />}</Image>
				<Content>
					<h5 className='text-primary m-0'>Live Site Details</h5>
					<TemplateInfo template={template_id} />
					<PublishInfo status={publish_status} date={last_published_date} />
					<Button as={Link} to={`/edit/meta/${business_vertical}/${site_name}`} variant='primary'>
						Edit Meta Info <i className='fas fa-edit'></i>
					</Button>
					<Button variant='dark' onClick={() => handleSubmit()}>
						Scan Site <i className='fas fa-redo'></i>
					</Button>
					{business_vertical === 'APM' && (
						<Button as={Link} to={`/listings/${site_name}`} variant='secondary'>
							Listings Data <i className='fas fa-database'></i>
						</Button>
					)}
					{user.isAdmin &&
						(allow_automated_qa ? (
							<Button variant='danger' onClick={() => handleAutoQA(false)}>
								Disable automated QA <i className='fas fa-minus-circle'></i>
							</Button>
						) : (
							<Button variant='success' onClick={() => handleAutoQA(true)}>
								Enable automated QA <i className='fas fa-minus-circle'></i>
							</Button>
						))}
				</Content>
			</Wrapper>
		</Component>
	);
};
