import { tidyUrl } from 'utils/Tidy';
import Success from 'components/shared/Success';
import Warning from 'components/shared/Warning';
import Error from 'components/shared/Error';
import Card from 'components/shared/Card';

const Collection = ({ collection_details }) => {
	// Destructure props
	const { database, number_of_listings } = { ...collection_details };

	// Render functions
	const renderDatabase = () => {
		// Disallowed databases
		const blacklist = ['demoapmplus', '.demo.appfolio', '.qa.appfolio'];

		if (database && number_of_listings !== null) {
			if (blacklist.some(name => database.includes(name))) {
				return <Error content={tidyUrl(database)} popover={"Should be connected to the customer's database."} />;
			} else {
				return <Success content={tidyUrl(database)} />;
			}
		} else {
			return <Error content={'Not found'} popover={'Database should exist.'} />;
		}
	};

	const renderListings = () => {
		if (number_of_listings > 0) {
			return <Success content={number_of_listings} />;
		} else {
			return <Warning content={'0'} popover={'This collection has no listings.'} />;
		}
	};

	return (
		<Card icon={'fas fa-database'} title={'Collection Data'}>
			<p className='text-secondary m-0'>AppFolio Database:</p>
			<p>{renderDatabase()}</p>
			<p className='text-secondary m-0'>Number of Listings:</p>
			<p>{renderListings()}</p>
		</Card>
	);
};

export default Collection;
