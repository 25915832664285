import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LoadingComponent = styled.div`
	position: absolute;
	inset: 0;
	display: grid;
	place-items: center;
	background: #fffe;
	z-index: 999;
	pointer-events: none;
	backdrop-filter: blur(3px);
`;

const Spinner = styled.div`
	width: 5rem;
	height: 5rem;
	color: var(--bs-light);
`;

export const Card = styled.div`
	overflow: hidden;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	background: white;
`;
export const Header = styled.div`
	background: var(--darkest);
	color: white;
	font-size: 1.25rem;
	font-weight: bold;
	padding: 0.75rem 1rem;
	display: flex;
	gap: 1rem;
	align-items: center;
`;

export const Body = styled.div`
	background: white;
`;

export const BodyRow = styled(Link)`
	display: flex;
	align-items: center;
	gap: 1rem;
	height: 5.5rem;
	border-bottom: 1px solid var(--lightest);
	padding: 1rem;
	text-decoration: none !important;
	color: inherit !important;
	transition: 0.2s;

	&:last-of-type {
		border: 0;
	}

	&:hover,
	&:focus {
		z-index: 1;
		box-shadow: rgb(0 0 0 / 18%) 0px 0px 3rem;
	}
`;

const SkeletonRow = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	height: 5.5rem;
	border-bottom: 1px solid var(--lightest);
	padding: 1rem;
	text-decoration: none !important;
	color: inherit !important;
	transition: 0.2s;

	&:last-of-type {
		border: 0;
	}
`;

const SkeletonBase = styled.div`
	height: 1rem;
	border-radius: 0.25rem;
	animation: flash 1.5s infinite;
	background: var(--bs-light);
`;

const SkeletonCircle = styled(SkeletonBase)`
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
`;

const SkeletonTextWrapper = styled.div`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const SkeletonTextShort = styled(SkeletonBase)`
	width: 50%;
	animation-delay: 0.25s;
`;

const SkeletonTextLong = styled(SkeletonBase)`
	width: 95%;
	animation-delay: 0.5s;
`;

export const Loading = ({ ...props }) => (
	<LoadingComponent {...props}>
		<Spinner className='spinner-border' />
	</LoadingComponent>
);

export const Skeleton = ({ ...props }) => {
	return (
		<div {...props}>
			{[...Array(10)].map((_, i) => (
				<SkeletonRow key={i}>
					<SkeletonCircle />
					<SkeletonTextWrapper>
						<SkeletonTextShort />
						<SkeletonTextLong />
					</SkeletonTextWrapper>
				</SkeletonRow>
			))}
		</div>
	);
};
