import Success from 'components/shared/Success';
import Warning from 'components/shared/Warning';
import Error from 'components/shared/Error';

export const MetaTitle = title => {
	if (title) {
		if (title.length >= 20 && title.length <= 60) {
			return <Success content={title} />;
		} else {
			return <Warning content={title} popover={'Meta title should be between 20 and 60 characters.'} />;
		}
	} else {
		return <Error content={'Not found'} popover={'Meta title should exist.'} />;
	}
};

export const MetaDescription = description => {
	if (description) {
		if (description.length >= 50 && description.length <= 160) {
			return <Success content={description} />;
		} else {
			return <Warning content={description} popover={'Meta description should be between 50 and 160 characters.'} />;
		}
	} else {
		return <Error content={'Not found'} popover={'Meta description should exist.'} />;
	}
};

export const MetaRobots = no_index => {
	if (!no_index) {
		return <Success content='Indexable by search engines' />;
	} else {
		return <Warning content={'noindex'} popover={'Not indexable by search engines as meta robots is set to "noindex".'} />;
	}
};
