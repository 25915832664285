import Card from 'components/shared/Card';
import Error from 'components/shared/Error';
import Success from 'components/shared/Success';
import Warning from 'components/shared/Warning';

const ProductTier = ({ product_details }) => {
	// Props
	const { page_count, product_tier } = { ...product_details };

	return (
		<Card icon={'fas fa-tag'} title={'Product Tier'}>
			<div className='mb-3'>
				<h6>Product Tier</h6>
				<span className='text-secondary'>Tier Name: </span>
				{product_tier?.name === 'Unknown' && <Error content={product_tier?.name} popover='Site should be associated with a valid product tier.' />}
				{product_tier?.name === 'Legacy' && <Warning content={product_tier?.name} popover='Legacy product tier should be updated to a current tier.' />}
				{product_tier?.name !== 'Unknown' && product_tier?.name !== 'Legacy' && (
					<>
						<Success content={product_tier?.name} />
						{product_tier?.page_limit && (
							<div>
								<span className='text-secondary mt-2'>Page Limit: </span>
								{page_count.total < product_tier?.page_limit && <Success content={product_tier?.page_limit} />}
								{page_count.total === product_tier?.page_limit && <Warning content={product_tier?.page_limit} popover={`Total page count of ${page_count.total} is at the limit for ${product_tier?.name} product tier.`} />}
								{page_count.total > product_tier?.page_limit && <Error content={product_tier?.page_limit} popover={`Total page count of ${page_count.total} exceeds page limit for ${product_tier?.name} product tier.`} />}
							</div>
						)}
					</>
				)}
			</div>
			<h6>Pages</h6>
			<div>
				<span className='text-secondary'>Static Page Count: </span>
				<span>{page_count.static}</span>
			</div>
			<div>
				<span className='text-secondary mt-2'>Dynamic Page Count: </span>
				<span>{page_count.dynamic}</span>
			</div>
			<div>
				<span className='text-secondary mt-2'>Total Page Count: </span>
				<span>{page_count.total}</span>
			</div>
		</Card>
	);
};

export default ProductTier;
