import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { tidyUrl, tidyDateTime } from 'utils/Tidy';
import Status from 'components/shared/Status';
import Vertical from 'components/shared/Vertical';
import Card from 'components/shared/Card';
import Confirmation from 'components/shared/Confirmation';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import useSWR from 'swr';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import Actions from './Actions';

const Component = styled.div`
	width: 600px;
	max-width: 100%;
	display: grid;
	gap: 1rem;
`;

const Label = styled.div`
	font-size: 0.9rem;
	color: var(--bs-secondary);
`;

const Content = styled.div`
	display: grid;
	gap: 0.5rem;
`;

const Job = () => {
	// State
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [refreshInterval, setRefreshInterval] = useState(0);

	// Hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { setMessage } = useMessage();
	const { data: job, isValidating, error } = useSWR(`/api/v1/jobs/${id}`, { refreshInterval });
	if (error) throw error;
	const { site_id, status, site_url, business_vertical, user_email, createdAt } = { ...job };

	// Effects
	useEffect(() => {
		status === 'Queued' || status === 'Processing' ? setRefreshInterval(10000) : setRefreshInterval(0);
	}, [status]);

	// Handlers
	const handleDeleteJob = async () => {
		setShowConfirmation(false);
		try {
			await fetcher(`/api/v1/jobs/${id}`, { method: 'DELETE' });
			navigate('/job/queue');
		} catch (error) {
			setMessage({ variant: 'danger', text: `There was an error deleting job ${id}.` });
		}
	};

	return (
		<>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={'delete this job from the queue.'} confirmFunction={handleDeleteJob} />
			<PageHeader heading={'Job Status'} subheading={'View the status of an existing job in the queue.'} />
			{!job ? (
				<LoadingOverlay />
			) : (
				<Component>
					<Actions job={job} isValidating={isValidating} setShowConfirmation={setShowConfirmation} />
					<Card icon={'fas fa-info-circle'} title={site_id}>
						<Content>
							<div>
								<Label>Job Status:</Label>
								<h4>
									<Status status={status} />
								</h4>
							</div>
							{site_url && (
								<div>
									<Label>Site URL:</Label>
									<div>
										<a href={site_url} target='_blank' rel='noopener noreferrer'>
											{tidyUrl(site_url)}
										</a>
									</div>
								</div>
							)}
							<div>
								<Label>Business Vertical:</Label>
								<div>
									<Vertical vertical={business_vertical} />
								</div>
							</div>
							<div>
								<Label>Created at:</Label>
								<div>{tidyDateTime(createdAt)}</div>
							</div>
							<div>
								<Label>Created by:</Label>
								<div>{user_email}</div>
							</div>
						</Content>
					</Card>
				</Component>
			)}
		</>
	);
};

export default Job;
