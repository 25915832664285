import { createRoot } from 'react-dom/client';
import { UserProvider } from 'utils/UserContext';
import { MessageProvider } from 'utils/MessageContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { config } from 'config/config';
import App from './App';
import './styles/App.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<ErrorBoundary>
		<Router>
			<CookiesProvider>
				<GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
					<UserProvider>
						<MessageProvider>
							<App />
						</MessageProvider>
					</UserProvider>
				</GoogleOAuthProvider>
			</CookiesProvider>
		</Router>
	</ErrorBoundary>
);
