import useSWR from 'swr';

export const useTemplates = () => {
	const { data: allTemplates } = useSWR('/api/v1/templates');

	// Return all templates
	const getAllTemplates = () => allTemplates;

	// Get template by ID
	const getTemplate = id => allTemplates?.find(template => template.id === id);

	return { getAllTemplates, getTemplate };
};
