import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLogin } from 'utils/UserContext';

const Component = styled.div`
	display: grid;
	gap: 1rem;
	justify-items: start;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 0;
		justify-items: stretch;
	}
`;

const Buttons = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.75rem;

	@media (max-width: 576px) {
		grid-template-columns: 100%;
	}
`;

const Label = styled.div`
	font-size: 0.9rem;
	color: var(--bs-secondary);
`;

const Actions = ({ job, isValidating, setShowConfirmation }) => {
	// State
	const [showLoader, setShowLoader] = useState(false);

	// Effects
	useEffect(() => {
		if (isValidating) {
			setShowLoader(true);
			setTimeout(() => setShowLoader(false), 2000);
		}
	}, [isValidating]);

	// Props
	const { status, site_id, report_id, failure_reason, business_vertical } = { ...job };

	// Hooks
	const { user } = useLogin();
	const { isAdmin } = { ...user };

	return (
		<Component>
			{status === 'Completed' && (
				<Buttons>
					<Button as={Link} to={`/report/${report_id}`} variant='primary'>
						View QA Report <i className='fas fa-file-alt'></i>
					</Button>
					<Button as={Link} to={`/site/${business_vertical}/${site_id}`} variant='secondary'>
						Report History <i className='fas fa-calendar-alt'></i>
					</Button>
					{isAdmin && (
						<Button variant='danger' onClick={() => setShowConfirmation(true)}>
							Delete Job <i className='fas fa-trash'></i>
						</Button>
					)}
				</Buttons>
			)}
			{status === 'Failed' && (
				<>
					<div>
						<Label>Failure Reason:</Label>
						<div>{failure_reason}</div>
					</div>
					{isAdmin && (
						<Button variant='danger' onClick={() => setShowConfirmation(true)}>
							Delete Job <i className='fas fa-trash'></i>
						</Button>
					)}
				</>
			)}
			{(status === 'Queued' || status === 'Processing') && (
				<>
					<div>
						<Label>Last checked:</Label>
						<div>
							{!showLoader ? (
								'A few seconds ago'
							) : (
								<span className='text-primary'>
									<i className='fas fa-spinner spin me-1'></i> Checking now...
								</span>
							)}
						</div>
					</div>
					{isAdmin && (
						<Button variant='danger' onClick={() => setShowConfirmation(true)}>
							Delete Job <i className='fas fa-trash'></i>
						</Button>
					)}
				</>
			)}
		</Component>
	);
};

export default Actions;
