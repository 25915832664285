import { useNavigate } from 'react-router-dom';
import { useLogin } from 'utils/UserContext';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import SiteIdInput from 'components/shared/SiteIdInput';

const New = () => {
	const navigate = useNavigate();
	const { user } = useLogin();
	const { setMessage } = useMessage();

	// Handlers
	const handleSubmit = async ({ site_id, business_vertical }) => {
		// Structure form body
		const body = {
			site_id,
			business_vertical,
			user_email: user.email,
			status: 'Queued',
			auto_created: false
		};

		// Add job to API
		try {
			const data = await fetcher('/api/v1/jobs/new', { method: 'POST', body: JSON.stringify(body) });
			navigate(`/job/${data.id}`);
		} catch (error) {
			setMessage({ variant: 'danger', text: `Error creating job - ${error.message}` });
		}
	};

	return (
		<>
			<h1>New QA Report</h1>
			<p className='text-secondary mb-md-5'>Run a new QA report against a specific website.</p>
			<SiteIdInput submitFunction={handleSubmit} />
		</>
	);
};

export default New;
