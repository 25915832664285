import { Dropdown } from 'react-bootstrap';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { useLogin } from 'utils/UserContext';
import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import { Cell, Header, RowLink, Table } from 'components/shared/Table';
import { tidyDate, tidyTime, tidyUrl } from 'utils/Tidy';
import Vertical from 'components/shared/Vertical';
import Status from 'components/shared/Status';

const ViewJobQueue = () => {
	// Hooks
	const { user } = useLogin();
	const { setMessage } = useMessage();
	const { data: allJobs, mutate } = useSWR('/api/v1/jobs/all', { revalidateOnFocus: true });

	// Clear jobs from queue
	const clearJobs = async job => {
		try {
			const jobCount = await fetcher(`/api/v1/jobs/clear?jobs=${job}`, { method: 'DELETE' });
			setMessage({
				variant: 'success',
				text: `Deleted ${jobCount} ${job} jobs from queue.`
			});
		} catch (error) {
			setMessage({
				variant: 'danger',
				message: `Error deleting ${job} from queue.`
			});
		} finally {
			await mutate();
		}
	};

	return (
		<>
			<PageHeader heading={'View Job Queue'} subheading={"View the job queue and find a queued job's details."} />
			{user.isAdmin && (
				<Dropdown className='mb-4'>
					<Dropdown.Toggle variant='danger' id='delete-dropdown'>
						Clear Jobs <i className='fas fa-angle-down'></i>
					</Dropdown.Toggle>
					<Dropdown.Menu className='p-0 shadow-sm'>
						<Dropdown.Item onClick={() => clearJobs('Processing')}>Clear Processing Jobs</Dropdown.Item>
						<Dropdown.Item onClick={() => clearJobs('Queued')}>Clear Queued Jobs</Dropdown.Item>
						<Dropdown.Item onClick={() => clearJobs('Failed')}>Clear Failed Jobs</Dropdown.Item>
						<Dropdown.Item onClick={() => clearJobs('')}>Clear All Jobs</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			)}
			{!allJobs ? (
				<LoadingOverlay />
			) : allJobs?.length === 0 ? (
				<h5 className='my-3'>No jobs in queue to display</h5>
			) : (
				<Table>
					<Header $cols='repeat(2, minmax(15rem, 1fr)) repeat(4, 10rem)'>
						<Cell>Site</Cell>
						<Cell>Created by</Cell>
						<Cell>Vertical</Cell>
						<Cell>Status</Cell>
						<Cell>
							Created At <i className='fas fa-angle-down text-secondary ms-1'></i>
						</Cell>
						<Cell>Auto-created</Cell>
					</Header>
					{allJobs.map(job => (
						<RowLink key={job._id} to={`/job/${job._id}`} $cols='repeat(2, minmax(15rem, 1fr)) repeat(4, 10rem)'>
							<Cell>
								{job.site_id}
								<small className='d-block text-secondary text-truncate'>{tidyUrl(job.site_url) || '-'}</small>
							</Cell>
							<Cell>{job.user_email}</Cell>
							<Cell>
								<Vertical vertical={job.business_vertical} short={true} />
							</Cell>
							<Cell>
								<Status status={job.status} />
							</Cell>
							<Cell>
								{tidyDate(job.createdAt)}
								<small className='d-block text-secondary'>{tidyTime(job.createdAt)}</small>
							</Cell>
							<Cell className='text-center'>{job.auto_created && <i className='fas fa-check text-primary'></i>}</Cell>
						</RowLink>
					))}
				</Table>
			)}
		</>
	);
};

export default ViewJobQueue;
