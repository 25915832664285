import 'chart.js/auto';
import { defaults } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { tidyDate } from 'utils/Tidy';
import { options } from 'utils/charts/Bar';
import useSWR from 'swr';
import styled from 'styled-components';
import { getChartColor } from 'utils/Colors';
import { NoData } from 'components/shared/Table';

const Component = styled.div`
	display: grid;
	place-items: center;
`;

const ClientPublishes = ({ site_id }) => {
	// UseSWR hook
	const { data: publish_data } = useSWR(`/api/v1/sites/client/${site_id}`);

	// Edit global chartjs defaults
	defaults.font.family = 'Poppins';
	defaults.font.color = 'var(--bs-dark)';

	// Format data
	const hasValidData = publish_data?.dates_published;
	const formattedData = publish_data?.dates_published
		.map(date => tidyDate(date))
		.reduce((total, current) => {
			const found = total.find(item => item.date === current);
			const value = { date: current, count: 1 };
			!found ? total.push(value) : found.count++;
			return total;
		}, []);

	const labels = formattedData?.map(date => date.date);
	const publishCount = formattedData?.map(date => date.count);

	// Chart data
	const data = {
		labels,
		datasets: [
			{
				label: 'Publish Count',
				data: publishCount,
				backgroundColor: getChartColor('primary')
			}
		]
	};

	return <Component>{hasValidData ? <Bar data={data} options={options} height={400} width={null} /> : <NoData />}</Component>;
};

export default ClientPublishes;
