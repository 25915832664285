import { useState } from 'react';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import styled from 'styled-components';
import { tidyShortDateTime } from 'utils/Tidy';
import { Link } from 'react-router-dom';
import DateSelector from 'components/Dashboard/DateSelector';
import Vertical from 'components/shared/Vertical';

const Component = styled.div`
	background: white;
	max-width: 100%;
	width: 1280px;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	border-radius: 0.5rem;
	min-height: 10rem;
	position: relative;
	overflow: hidden;
`;

const Header = styled.div`
	background: var(--darkest);
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Heading = styled.h5`
	color: white;
	margin: 0;
`;

const Entry = styled.div`
	display: grid;
	grid-template-columns: 12rem auto;
	gap: 2rem;
	border-bottom: 1px solid #eee;
	padding: 1rem;
	overflow: auto;
`;

const Icon = styled.span`
	margin-right: 0.5rem;
`;

const Error = styled.div`
	font-size: 0.9rem;
	display: grid;
	gap: 0.5rem;
`;

const Details = styled.code`
	display: inline-block;
	margin: 0.5rem 0 0;
	padding: 0.5rem 0.75rem;
	border-radius: 0.5rem;
	background: var(--lightest);
	overflow: hidden;
`;

const Email = styled.div`
	color: var(--bs-secondary);
`;

const NoEntries = styled.div`
	text-align: center;
	padding: 2rem;
`;

const Logs = () => {
	// State
	const [period, setPeriod] = useState(1);

	// Hooks
	const { data: logs } = useSWR(`/api/v1/logs/all?dates=${period}`);

	// Render
	const renderIcon = type => {
		const iconMap = {
			error: <i className='far fa-times-circle text-danger'></i>,
			warning: <i className='fas fa-exclamation-triangle text-warning'></i>,
			success: <i className='far fa-check-circle text-success'></i>,
			info: <i className='fas fa-info-circle text-secondary'></i>
		};
		return iconMap[type];
	};

	// Handlers
	const handleDateChange = e => {
		setPeriod(e);
	};

	return (
		<div>
			<PageHeader heading={'Logs Admin'} subheading={'View app log history with error reports and problem sites.'} />
			<Component>
				<Header>
					<Heading>
						<i className='fas fa-history text-secondary me-3'></i>Recent Logs
					</Heading>
					<DateSelector defaultValue={1} handleDateChange={handleDateChange} />
				</Header>
				{logs ? (
					logs.length > 0 ? (
						<>
							{logs.map(log => (
								<Entry key={log._id}>
									<div>{tidyShortDateTime(log.createdAt)}</div>
									<Error>
										<h6>
											<Icon>{renderIcon(log.type)}</Icon>
											{log.title}
										</h6>
										<Vertical vertical={log.business_vertical} />
										<div>
											<div>
												Site: <Link to={`/site/${log.business_vertical}/${log.site_id}`}>{log.site_id}</Link>
											</div>
											{log.page_url && (
												<div>
													Page:{' '}
													<a href={log.page_url} target='_blank' rel='noopener noreferrer'>
														{log.page_url}
													</a>
												</div>
											)}
											<Details>{log.details}</Details>
											<Email>{log.user_email}</Email>
										</div>
									</Error>
								</Entry>
							))}
						</>
					) : (
						<NoEntries>No logs entries to display.</NoEntries>
					)
				) : (
					<LoadingOverlay />
				)}
			</Component>
		</div>
	);
};

export default Logs;
