import Card from 'components/shared/Card';
import Success from 'components/shared/Success';
import Warning from 'components/shared/Warning';

const AudioEye = ({ audio_eye }) => {
	// Props
	const { audioeye_enabled } = { ...audio_eye };

	return (
		<Card icon={'fas fa-universal-access'} title={'AudioEye'}>
			<p className='text-secondary m-0'>
				Status: <span className='text-dark'>{audioeye_enabled ? <Success content={'OK'} /> : <Warning content={'Not enabled'} popover={'Ensure the widget is enabled in the App Store unless the customer has explicitly opted out'} />}</span>
			</p>
		</Card>
	);
};

export default AudioEye;
