import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';

const Component = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const FormText = ({ siteId, isValid }) => {
	if (!siteId)
		return (
			<Form.Text>
				<i className='fas fa-info-circle me-2'></i>
				Enter a unique 8-digit Duda site ID.
			</Form.Text>
		);

	return isValid ? (
		<Form.Text>
			<i className='fas fa-check-circle text-success me-2'></i>
			Site ID found.
		</Form.Text>
	) : (
		<Form.Text>
			<i className='fas fa-times-circle text-danger me-2'></i>
			Site ID not found.
		</Form.Text>
	);
};

const SiteIdInput = ({ submitFunction, apmOnly = false, ...args }) => {
	// State
	const [vertical, setVertical] = useState('APM');
	const [siteId, setSiteId] = useState('');
	const [isValid, setIsValid] = useState(false);

	// Hooks
	const { data: siteIds } = useSWR(`/api/v1/sites/${vertical}/all`);
	const [searchParams, setSearchParams] = useSearchParams();

	// Effects
	useEffect(() => {
		siteId.length === 8 && siteIds?.includes(siteId) ? setIsValid(true) : setIsValid(false);
	}, [siteId, siteIds, vertical]);

	useEffect(() => {
		const searchString = searchParams.toString();
		if (searchString.length > 0) {
			setVertical(searchParams.get('vertical'));
			setSiteId(searchParams.get('id'));
			setSearchParams('');
		}
	}, [searchParams, setSearchParams]);

	return (
		<Form
			onSubmit={e => {
				e.preventDefault();
				submitFunction({ business_vertical: vertical, site_id: siteId, ...args });
				setSiteId('');
				setIsValid(false);
			}}>
			<Component>
				<div>
					<Form.Group>
						<Form.Label>Business vertical</Form.Label>
						<Form.Select value={vertical} onChange={e => setVertical(e.target.value)} className='mb-2'>
							<option value='APM'>AppFolio Property Manager</option>
							<option value='IM' disabled={apmOnly}>
								AppFolio Investment Manager
							</option>
						</Form.Select>
						<Form.Text>
							<i className='fas fa-info-circle me-2'></i>Duda account the site is associated with.
						</Form.Text>
					</Form.Group>
				</div>
				<div>
					<Form.Group>
						<Form.Label>Duda Site ID</Form.Label>
						<Form.Control type='input' value={siteId} onChange={e => setSiteId(e.target.value)} minLength={8} maxLength={8} placeholder='Enter a site ID...' pattern='[a-zA-Z0-9]{8}$' className='mb-2' required />
						<FormText siteId={siteId} isValid={isValid} />
					</Form.Group>
				</div>
			</Component>
			<Button variant='primary' type='submit' className='mt-3' disabled={!isValid}>
				Submit <i className='fas fa-chevron-right'></i>
			</Button>
		</Form>
	);
};

export default SiteIdInput;
