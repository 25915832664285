import Dashboard from 'components/Dashboard/Dashboard';
import Lowest from 'components/Dashboard/Lowest/Lowest';
import New from 'components/Report/New';
import FindSite from 'components/Site/Find/FindSite';
import ViewJobQueue from 'components/Job/ViewJobQueue/ViewJobQueue';
import Job from 'components/Job/Job';
import Report from 'components/Report/Report';
import Overview from 'components/Site/Details/Overview';
import EditMeta from 'components/Edit/Meta/Meta';
import Listings from 'components/Listings/Listings';
import ListingsDetails from 'components/Listings/Details';
import SitesAdmin from 'components/Admin/Sites';
import LogsAdmin from 'components/Admin/Logs';
import Error from 'components/pages/Error';
import NotFound from 'components/pages/NotFound';
import Footer from './Footer';
import { Routes, Navigate, Route, Outlet } from 'react-router-dom';
import { useLogin } from 'utils/UserContext';
import styled from 'styled-components';
import MessageBar from 'components/shared/MessageBar';
import PageCount from 'components/Audits/PageCount';
import { ErrorBoundary } from 'react-error-boundary';

const Component = styled.main`
	grid-area: main;
	display: flex;
	flex-direction: column;
	overflow: hidden auto;
`;

const Wrapper = styled.div`
	max-width: 100%;
	width: 1280px;
	margin: 0 auto;
	padding: 2rem;

	@media (max-width: 767px) {
		padding: 1rem;
	}
`;

export default function Main() {
	// Hooks
	const { user } = useLogin();
	const { isAdmin } = { ...user };

	return (
		<ErrorBoundary FallbackComponent={Error}>
			<Component>
				<MessageBar />
				<Wrapper>
					<Routes>
						<Route index element={<Navigate to='dashboard' />} />
						<Route path='dashboard'>
							<Route index element={<Dashboard />} />
							<Route path='lowest' element={<Lowest />} />
						</Route>
						<Route path='job'>
							<Route path=':id' element={<Job />} />
							<Route path='queue' element={<ViewJobQueue />} />
						</Route>
						<Route path='site'>
							<Route path='find' element={<FindSite />} />
							<Route path=':vertical'>
								<Route path=':id' element={<Overview />} />
							</Route>
						</Route>
						<Route path='admin' element={isAdmin ? <Outlet /> : <Navigate to='/' />}>
							<Route path='sites' element={<SitesAdmin />} />
							<Route path='logs' element={<LogsAdmin />} />
						</Route>
						<Route path='report'>
							<Route path='new' element={<New />} />
							<Route path=':id' element={<Report />} />
						</Route>
						<Route path='listings'>
							<Route path='find' element={<Listings />} />
							<Route path=':id' element={<ListingsDetails />} />
						</Route>
						<Route path='edit'>
							<Route path='meta'>
								<Route path=':vertical'>
									<Route path=':id' element={<EditMeta />} />
								</Route>
							</Route>
						</Route>
						<Route path='audits'>
							<Route path='page-count' element={<PageCount />} />
						</Route>
						<Route path='error' element={<Error />} />
						<Route path='*' element={<NotFound />} />
					</Routes>
				</Wrapper>
				<Footer />
			</Component>
		</ErrorBoundary>
	);
}
