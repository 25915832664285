import { useState } from 'react';
import styled from 'styled-components';
import DateSelector from './DateSelector';
import { Bar } from 'react-chartjs-2';
import { tidyUTCDate } from 'utils/Tidy';
import { options } from 'utils/charts/Bar';
import { getDates } from 'utils/DateRanges';

import useSWR from 'swr';
import { Loading } from './Components';
import { getChartColor } from 'utils/Colors';
import { NoData } from 'components/shared/Table';

const ChartWrapper = styled.div`
	position: relative;
	min-height: 400px;
`;

const Card = styled.div`
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	border-radius: 0.5rem;
	overflow: auto;
	background: white;
	position: relative;
`;

const CardHeader = styled.div`
	display: flex;
	background: var(--darkest);
	color: white;
	border-radius: 0.5rem 0.5rem 0 0;
	align-items: center;
	padding: 1rem;
`;

const CompletedByDate = ({ ...props }) => {
	// Component state
	const [date, setDate] = useState('14');

	// Handle date change
	const handleDateChange = e => {
		setDate(e);
	};

	// Use SWR Hook
	const { data: events } = useSWR(`/api/v1/dashboard/date?dates=${date}`);

	// Get all dates in range
	const allDates = getDates(date);

	// Format response data to array of objects
	const formattedData = events?.reduce((total, current) => {
		const found = total.find(item => item.date === tidyUTCDate(current.createdAt));
		const value = { date: tidyUTCDate(current.createdAt), count: 1 };
		!found ? total.push(value) : found.count++;
		return total;
	}, []);

	// Generate new array of publish totals
	const publishCount = allDates?.map(date => {
		const found = formattedData?.find(item => item.date === date);
		return found ? found.count : 0;
	});

	// Chart configuration
	const chartData = {
		labels: allDates,
		datasets: [
			{
				label: 'Report Count',
				data: publishCount,
				backgroundColor: getChartColor('primary')
			}
		]
	};

	return (
		<Card {...props}>
			<CardHeader>
				<h5 className='m-0 text-secondary'>
					<i className='me-3 fas fa-calendar-alt'></i>
				</h5>
				<div>
					<h5 className='m-0'>Reports Completed</h5>
				</div>
				<DateSelector handleDateChange={handleDateChange} />
			</CardHeader>
			<div className='p-3'>
				<ChartWrapper>{events ? events?.length > 0 ? <Bar data={chartData} options={options} height={400} width={null} /> : <NoData /> : <Loading />}</ChartWrapper>
			</div>
		</Card>
	);
};

export default CompletedByDate;
