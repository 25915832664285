import { Doughnut } from 'react-chartjs-2';
import { getChartColor, getScaleInfo } from 'utils/Colors';
import styled from 'styled-components';
import { options } from 'utils/charts/Doughnut';

const Component = styled.div`
	position: relative;
	width: 50px;
	height: 50px;
`;

const Number = styled.h6`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: ${({ $color }) => getChartColor($color)};
`;

const Score = ({ score }) => {
	// Color variable
	const { color } = getScaleInfo(score);

	// Chart data
	const data = {
		datasets: [
			{
				data: [score, 100 - score],
				backgroundColor: [getChartColor(color), '#eee'],
				borderWidth: 0
			}
		],
		labels: ['Score']
	};

	return (
		<Component>
			<Doughnut data={data} options={options} width={50} height={50} />
			<Number $color={color}>{score}</Number>
		</Component>
	);
};

export default Score;
