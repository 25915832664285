import appfolio_logo from 'img/appfolio_logo.svg';
import appfolio_im_logo from 'img/appfolio_im_logo.svg';
import styled from 'styled-components';

const Component = styled.div`
	display: flex;
	gap: 0.25rem;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Text = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Vertical = ({ vertical, short = false }) => {
	const name = !short ? (vertical === 'IM' ? 'AppFolio Investment Manager' : 'AppFolio Property Manager') : vertical;
	const logo = vertical === 'IM' ? appfolio_im_logo : appfolio_logo;

	return (
		<Component>
			<img src={logo} className='me-1' width='20' alt={name} /> <Text>{name}</Text>
		</Component>
	);
};

export default Vertical;
