import { Button } from 'react-bootstrap';
import { useState } from 'react';
import styled from 'styled-components';
import Map from './Map';
import useSWR from 'swr';

const Component = styled.div`
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	overflow: hidden;
`;

const Header = styled.h5`
	background: var(--darkest);
	color: white;
	border-radius: 0.5rem 0.5rem 0 0;
	padding: 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	margin: 0;
`;

const Content = styled.div`
	background: white;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: start;

	@media (max-width: 991px) {
		grid-template-columns: 100%;
	}
`;

const List = styled.div`
	overflow-y: auto;
	height: 50rem;

	@media (max-width: 991px) {
		height: auto;
		max-height: 50rem;
	}
`;

const Listing = styled.div`
	cursor: pointer;
	padding: 1rem 1.5rem;
	line-height: 1.75;
	border-bottom: 1px solid var(--lightest);
	text-decoration: none !important;
	overflow: hidden;

	div {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&:hover,
	&:focus-visible {
		background: var(--lightest);
		color: initial !important;
	}

	&.active {
		background: var(--bs-primary);
		color: white !important;

		.text-secondary {
			color: white !important;
		}
	}
`;

const Coords = styled.div`
	display: flex;
	gap: 1rem;
`;

const LocationData = ({ collections }) => {
	// Props
	const initialActiveListing = collections[0];

	// State
	const [activeListing, setActiveListing] = useState(initialActiveListing);

	// Hooks
	const { data } = useSWR('/api/v1/config');

	return (
		<Component>
			<Header>
				<i className='fas fa-map text-secondary'></i> Location Data
			</Header>
			<Content>
				<Wrapper>
					<List>
						{collections.map(listing => (
							<Listing key={listing.page_item_url} onClick={() => setActiveListing(listing)} tabIndex='0' className={activeListing.page_item_url === listing.page_item_url && 'active'}>
								<div>{listing.page_item_url}</div>
								<div className='text-secondary'>{listing.data.full_address}</div>
								<Coords>
									<small>lat: {listing.data.location.geo.latitude}</small>
									<small>lng: {listing.data.location.geo.longitude}</small>
								</Coords>
								{activeListing.page_item_url === listing.page_item_url && (
									<Button variant='outline-light' size='sm' className='mt-2' href={`https://www.google.com/maps/search/?api=1&query=${listing.data.location.geo.latitude}%2C${listing.data.location.geo.longitude}&zoom=15`} target='_blank' rel='noopener noreferrer'>
										View in Google Maps <i className='fas fa-external-link-alt'></i>
									</Button>
								)}
							</Listing>
						))}
					</List>
					{data?.google_maps_api_key && <Map activeListing={activeListing} googleMapsApiKey={data.google_maps_api_key} />}
				</Wrapper>
			</Content>
		</Component>
	);
};

export default LocationData;
