import { useParams, Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import Card from 'components/shared/Card';
import EditTitle from './EditTitle';
import EditDescription from './EditDescription';
import { tidyUrl } from 'utils/Tidy';
import { useCSVReader } from 'react-papaparse';
import useSWR from 'swr';
import { fetcher } from 'utils/Fetch';
import styled from 'styled-components';
import { useMessage } from 'utils/MessageContext';
import { Table, Header, Row, Cell } from 'components/shared/Table';
import { useEffect, useState } from 'react';

const Component = styled.div`
	display: grid;
	gap: 1.5rem;
`;

const Buttons = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.75rem;

	@media (max-width: 576px) {
		flex-direction: column;
	}
`;

const Meta = () => {
	// Hooks
	const { id, vertical } = useParams();
	const { setMessage } = useMessage();
	const { data, mutate } = useSWR(`/api/v1/sites/meta/${vertical}/${id}`);
	const { CSVReader } = useCSVReader();

	// State
	const [isLoading, setIsLoading] = useState(!data);

	// Sort pages alphabetically
	const sortPages = pages => {
		// Sort alphabetically
		const home = pages.find(page => page.path === 'home');
		const sorted = pages.filter(page => page.path !== 'home').sort((a, b) => a.title.localeCompare(b.title));
		return [{ ...home, path: '' }, ...sorted];
	};

	// Props
	const { site_pages, site_details } = { ...data };
	const sortedPages = site_pages ? sortPages(site_pages) : [];
	const { site_domain, publish_status, preview_site_url, business_vertical } = { ...site_details };
	const published = publish_status === 'PUBLISHED' ? true : false;
	const $cols = 'repeat(2, minmax(15rem, 1fr)) repeat(2, minmax(25rem, 2fr))';

	// Effects
	useEffect(() => {
		if (data) setIsLoading(false);
	}, [data]);

	// Form site URL
	let site_url;
	if (site_details) {
		if (publish_status === 'PUBLISHED') {
			if (site_domain) {
				site_url = `http://${site_details.site_domain}`;
			} else {
				site_url = `http://${site_details.site_default_domain}`;
			}
		} else {
			site_url = preview_site_url.replace('preview', 'site');
		}
	}

	// Generate CSV
	const csvRows = sortedPages ? sortedPages.map(page => [[page.uuid], [page.title], [`${site_url}/${page.path}`], [page.seo.title], [page.seo.description]]) : [];
	const csvData = [['Page ID', 'Page Name', 'Page URL', 'Meta Title', 'Meta Description'], ...csvRows];

	// Handlers
	const handleSaveAndPublish = async () => {
		setIsLoading(true);
		try {
			await fetcher(`/api/v1/sites/republish/${business_vertical}/${id}`, { method: 'POST' });
			await mutate();
			setMessage({
				variant: 'success',
				text: `Successfully saved meta info and republished site ${id}.`
			});
		} catch (error) {
			await mutate();
			setMessage({
				variant: 'danger',
				text: `Error saving meta info or republishing site ${id}.`
			});
		}
		setIsLoading(false);
	};

	// Manipulate uploaded csv
	const handleCSVUpload = async results => {
		setIsLoading(true);
		const { data: rows } = results;

		// Generate meta body
		for (let row of rows) {
			const body = { uuid: row['Page ID'], title: row['Meta Title'], description: row['Meta Description'] };
			try {
				await fetcher(`/api/v1/sites/meta/${business_vertical}/${id}`, { method: 'POST', body: JSON.stringify(body) });
			} catch (error) {
				setMessage({
					variant: 'danger',
					message: `Could not import line ${row['Page ID']}.`
				});
				break;
			}
		}
		await mutate();
		setMessage({
			variant: 'success',
			text: `All meta info changes were imported successfully.`
		});
		setIsLoading(false);
	};

	return isLoading ? (
		<LoadingOverlay />
	) : (
		<>
			<PageHeader heading={'Edit Meta Info'} subheading={"Edit a site's meta titles & descriptions directly."} />
			<Component>
				<Card
					icon={'fas fa-info-circle'}
					title={id}
					subtitle={
						<a className='text-secondary' href={site_url} target='_blank' rel='noopener noreferrer'>
							{tidyUrl(site_url)}
						</a>
					}>
					<Buttons>
						<CSVLink data={csvData} filename={id + ' Meta Tags.csv'} target='_blank'>
							<Button variant='dark'>
								Export CSV <i className='fas fa-file-export'></i>
							</Button>
						</CSVLink>
						<CSVReader config={{ header: true }} onUploadAccepted={results => handleCSVUpload(results)} noDrag>
							{({ getRootProps }) => (
								<Button variant='info' {...getRootProps()}>
									Import CSV <i className='fas fa-file-import'></i>
								</Button>
							)}
						</CSVReader>
						{published && (
							<Button variant='primary' onClick={() => handleSaveAndPublish()} disabled={isLoading}>
								{!isLoading ? (
									<>
										Save & Republish Site <i className='fas fa-save'></i>
									</>
								) : (
									<Spinner size='sm' />
								)}
							</Button>
						)}
						<Button as={Link} variant='secondary' to={`/site/${vertical}/${id}`}>
							Back to Site Overview <i className='fas fa-sitemap'></i>
						</Button>
					</Buttons>
				</Card>
				<Table>
					<Header $cols={$cols}>
						<Cell>Page Name</Cell>
						<Cell>Page URL</Cell>
						<Cell>Meta Title</Cell>
						<Cell>Meta Description</Cell>
					</Header>
					{sortedPages.map(page => (
						<Row key={page.uuid} $cols={$cols}>
							<Cell>{page.title}</Cell>
							<Cell>
								<a href={`${site_url}/${page.path}`} className='text-dark' target='_blank' rel='noopener noreferrer'>
									{page.path}
								</a>
							</Cell>
							<Cell $wrap={true}>
								<EditTitle siteId={id} business_vertical={business_vertical} page={page} fetchData={mutate} />
							</Cell>
							<Cell $wrap={true}>
								<EditDescription siteId={id} business_vertical={business_vertical} page={page} fetchData={mutate} />
							</Cell>
						</Row>
					))}
				</Table>
			</Component>
		</>
	);
};

export default Meta;
