import styled from 'styled-components';
import CompletedByDate from './CompletedByDate';
import ClientPublishEvents from './ClientPublishEvents';
import LatestReports from './LatestReports';
import LowestScore from './LowestScore';
import MostPublished from './MostPublished';
import PageHeader from 'components/shared/PageHeader';

const Component = styled.div`
	display: grid;
	gap: 1.5rem;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: 2;

	@media (max-width: 991px) {
		grid-template-columns: 100%;

		> div {
			grid-column: auto !important;
		}
	}

	& :nth-child(1) {
		grid-column: 1/4;
	}

	& :nth-child(2) {
		grid-column: 4/7;
	}

	& :nth-child(3) {
		grid-column: 1/3;
	}

	& :nth-child(4) {
		grid-column: 3/5;
	}

	& :nth-child(5) {
		grid-column: 5/7;
	}
`;

const Dashboard = () => {
	return (
		<>
			<PageHeader heading={'Dashboard'} subheading={'Metrics for all recently completed QA reports.'} />
			<Component>
				<CompletedByDate />
				<ClientPublishEvents />
				<LatestReports />
				<LowestScore />
				<MostPublished />
			</Component>
		</>
	);
};

export default Dashboard;
