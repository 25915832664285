import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Vertical from 'components/shared/Vertical';
import { tidyUrl, tidyDateTime } from 'utils/Tidy';
import { useLogin } from 'utils/UserContext';
import Confirmation from 'components/shared/Confirmation';
import styled from 'styled-components';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import PublishInfo from 'components/shared/PublishInfo';
import TemplateInfo from 'components/shared/TemplateInfo';

const Component = styled.div`
	background: white;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	border-radius: 0.5rem;
	overflow: hidden;
`;

const Header = styled.div`
	background: var(--darkest);
	padding: 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const Icon = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
	object-position: center;
`;

const ThumbWrapper = styled.div`
	position: relative;
	overflow: hidden;

	@media (max-width: 576px) {
		height: 20rem;
	}
`;

const Thumbnail = styled.img`
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
`;

const Card = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr) 15rem;
	gap: 1rem;

	@media (max-width: 1280px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 576px) {
		grid-template-columns: 100%;
		gap: 0;
	}
`;

const Content = styled.div`
	display: grid;
	gap: 0.75rem;
	align-content: start;
	padding: 1.5rem;

	@media (max-width: 1280px) {
		order: 1;
		padding: 1rem;
	}
`;

const Buttons = styled(Content)`
	@media (max-width: 1280px) {
		order: 0;
	}

	@media (max-width: 576px) {
		.btn {
			margin: 0 !important;
		}
	}
`;

const Heading = styled.h5`
	margin: 0;
	color: var(--bs-primary);
`;

const Field = styled.div`
	color: var(--bs-secondary);
`;

const MainInfo = ({ report }) => {
	// Props
	const { _id, site_url, site_id, site_details, site_pages, business_vertical, user_email, createdAt, report_duration } = { ...report };
	const { site_seo, last_published_date, publish_status, fav_icon, thumbnail_url, template_id } = site_details;
	const site_icon = site_seo?.og_image || fav_icon;

	// State
	const [showConfirmation, setShowConfirmation] = useState(false);

	// Hooks
	const navigate = useNavigate();
	const { user } = useLogin();
	const { setMessage } = useMessage();

	// Handlers
	const handleSubmit = async () => {
		const user_email = user.email;
		const status = 'Queued';
		const auto_created = false;

		// Structure form body
		const body = { site_id, site_url, business_vertical, user_email, status, auto_created };

		// Add job to API
		try {
			const { id } = await fetcher('/api/v1/jobs/new', { method: 'POST', body: JSON.stringify(body) });
			navigate(`/job/${id}`);
		} catch (error) {
			setMessage({ variant: 'danger', text: 'There was an error creating this job.' });
		}
	};

	// Handle delete job
	const handleDeleteReport = async () => {
		setShowConfirmation(false);
		try {
			await fetcher(`/api/v1/reports/${_id}`, { method: 'DELETE' });
			navigate(`/site/${business_vertical}/${site_id}`);
		} catch (error) {
			setMessage({ variant: 'danger', text: 'There was an error creating this job.' });
		}
	};

	return (
		<>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={'delete this report indefinitely.'} confirmFunction={handleDeleteReport} />
			<Component>
				<Header>
					{site_icon && <Icon src={site_icon} alt={tidyUrl(site_url)} />}
					<div>
						<h4 className='text-white text-truncate m-0'>{site_id}</h4>
						<a className='text-secondary m-0' style={{ wordWrap: 'break-word' }} href={site_url} target='_blank' rel='noopener noreferrer'>
							{tidyUrl(site_url)}
						</a>
					</div>
				</Header>
				<Card>
					<ThumbWrapper>
						{thumbnail_url ? (
							<Thumbnail src={thumbnail_url} alt={site_url} />
						) : (
							<div>
								<i className='fas fa-file-image' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '5rem', color: '#6c757d' }}></i>
							</div>
						)}
					</ThumbWrapper>
					<Content>
						<Heading>Live Site Details</Heading>
						<div>
							<Field>Business Vertical:</Field>
							<Vertical vertical={business_vertical} />
						</div>
						<TemplateInfo template={template_id} />
						<PublishInfo status={publish_status} date={last_published_date} />
					</Content>
					<Content>
						<Heading>Report Details</Heading>
						<div>
							<Field>Completed:</Field>
							<div>{tidyDateTime(createdAt)}</div>
						</div>
						<div>
							<Field>Created by:</Field>
							<div>{user_email}</div>
						</div>
						<div>
							<Field>Pages scanned:</Field>
							<div>{site_pages.length}</div>
						</div>
						<div>
							<Field>Duration:</Field>
							<div>{report_duration} seconds</div>
						</div>
					</Content>
					<Buttons>
						<Button as={Link} variant='primary' to={`/edit/meta/${business_vertical}/${site_id}`}>
							Edit Meta Info <i className='fas fa-edit'></i>
						</Button>
						<Button variant='dark' onClick={() => handleSubmit()}>
							Scan Site <i className='fas fa-redo'></i>
						</Button>
						<Button as={Link} variant='secondary' to={`/site/${business_vertical}/${site_id}`}>
							Site Overview <i className='fas fa-sitemap'></i>
						</Button>
						{user.isAdmin && (
							<Button variant='danger' onClick={() => setShowConfirmation(true)}>
								Delete Report <i className='fas fa-trash'></i>
							</Button>
						)}
					</Buttons>
				</Card>
			</Component>
		</>
	);
};

export default MainInfo;
