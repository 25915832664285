import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const Component = styled(Form.Select)`
	background: var(--bs-dark);
	color: white;
	width: auto;
	margin-left: auto;
`;

const DateSelector = ({ defaultValue = '14', handleDateChange }) => {
	return (
		<Component onChange={e => handleDateChange(e.target.value)} defaultValue={defaultValue}>
			<option value='90'>Last 90 Days</option>
			<option value='60'>Last 60 Days</option>
			<option value='30'>Last 30 Days</option>
			<option value='14'>Last 14 Days</option>
			<option value='7'>Last 7 Days</option>
			<option value='1'>Today</option>
		</Component>
	);
};

export default DateSelector;
