import SVG from 'react-inlinesvg';
import { Accordion, AccordionCollapse } from 'react-bootstrap';
import Card from 'components/shared/Card';
import Success from 'components/shared/Success';
import Error from 'components/shared/Error';
import styled from 'styled-components';
import Toggle from 'components/shared/Toggle';

const Component = styled.div`
	display: grid;
	gap: 1rem;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
	gap: 1rem;
`;

const Item = styled.div`
	border-radius: 0.5rem;
	overflow: hidden;
`;

const Image = styled.div`
	aspect-ratio: 4 / 3;
	width: 100%;
	position: relative;
	border: 1px solid var(--lightest);
	border-radius: 0.5rem 0.5rem 0 0;
	overflow: hidden;
`;

const Icon = styled(SVG)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 5rem;
	height: 5rem;
`;

const Alt = styled.div`
	padding: 0.75rem;
	line-height: 1.25;
	background: var(--lightest);
	transition: 0.2s;
`;

const List = styled.ul`
	margin: 0;
`;

const Icons = ({ icons = [] }) => {
	// Destructure props
	const iconErrors = icons.filter(icon => !icon.alt_text || !icon.alt_text === 'Default alt text');
	const iconSuccesses = icons.filter(icon => icon.src === 'OK');

	return (
		<>
			{icons?.length > 0 && (
				<Card icon={'fas fa-icons'} title={'Icons'}>
					<Component>
						{iconErrors?.length > 0 && (
							<Wrapper>
								{iconErrors?.map((icon, index) => (
									<Item key={index} title={icon.alt_text}>
										<Image>
											<Icon src={icon.src} alt={icon.alt_text} />
										</Image>
										<Alt>
											<p className='text-secondary m-0'>Alt Text:</p>
											<p className='text-nowrap text-truncate m-0'>
												<Error content={'Not found'} popover={'Each icon should have descriptive alt text that is not placeholder text.'} />
											</p>
										</Alt>
									</Item>
								))}
							</Wrapper>
						)}
						{iconSuccesses?.length > 0 && (
							<Accordion>
								<Toggle eventKey='0'>
									Show list of <Success content={iconSuccesses.length} /> icon{iconSuccesses.length > 1 && 's'} with descriptive alt text. <i className='fas fa-angle-down text-secondary'></i>
								</Toggle>
								<AccordionCollapse eventKey='0' className='mt-2'>
									<List>
										{iconSuccesses.map((icon, index) => (
											<li key={index}>{icon.alt_text}</li>
										))}
									</List>
								</AccordionCollapse>
							</Accordion>
						)}
					</Component>
				</Card>
			)}
		</>
	);
};

export default Icons;
