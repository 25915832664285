import Success from 'components/shared/Success';
import Error from 'components/shared/Error';
import Card from 'components/shared/Card';

const CustomWidgets = ({ page }) => {
	// Destructure data
	const { custom_widgets } = { ...page };

	// Render functions
	const renderStatus = status => {
		if (status === 'OK' || status === true) {
			return <Success content={'OK'} />;
		} else {
			return <Error content={'Error'} popover={'Ensure the widget is connected to data'} />;
		}
	};

	return (
		<>
			{custom_widgets && custom_widgets.length > 0 && (
				<Card icon={'fas fa-code'} title={'Custom Widgets'}>
					<div style={{ display: 'grid', gap: '1rem' }}>
						{custom_widgets.map((widget, index) => (
							<div key={index}>
								<p className='text-secondary m-0'>
									Name: <span className='text-dark'>{widget.name}</span>
								</p>
								<p className='text-secondary m-0'>
									Version: <span className='text-dark'>{widget.version}</span>
								</p>
								<p className='text-secondary m-0'>
									Status: <span className='text-dark'>{renderStatus(widget.status || widget.connected)}</span>
								</p>
							</div>
						))}
					</div>
				</Card>
			)}
		</>
	);
};

export default CustomWidgets;
