import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

// Extensions
dayjs.extend(relativeTime);
dayjs.extend(utc);

// Tidy URLs
export function tidyUrl(url) {
	return url ? url.replace('http://', '').replace('https://', '').replace(/\/$/g, '') : '';
}

// Tidy dates
export function tidyDate(date, format = 'MM/DD/YYYY') {
	return date ? dayjs(date).format(format) : '';
}

export function tidyUTCDate(date) {
	return date ? dayjs(date).utc().format('MM/DD/YYYY') : '';
}

export function tidyTime(date) {
	return date ? dayjs(date).format('h:mm A') : '';
}

export function tidyDateTime(date) {
	return date ? dayjs(date).format('MMMM D YYYY @ h:mm A') : '';
}

export function tidyShortDateTime(date) {
	return date ? dayjs(date).format('MM/DD/YYYY @ h:mm A') : '';
}

export function tidyRelativeDateTime(date) {
	return date ? dayjs(date).toNow() : '';
}

// Tidy text
export function tidyCapitalize(text) {
	return text
		.toLowerCase()
		.split(/ |_/)
		.map(word => word.charAt(0).toUpperCase() + word.substring(1))
		.join(' ');
}

export function tidyUnderscores(text) {
	return text
		.toLowerCase()
		.split('_')
		.map(word => word.charAt(0).toUpperCase() + word.substring(1))
		.join(' ');
}

// Tidy database strings
export function tidyDatabase(url) {
	const urlObject = new URL(url);
	if (urlObject.hostname === 'api.appfoliotest.com') {
		const db = urlObject.pathname.replace('/api/v1/listings/', '');
		return `${db}.appfolio.com`;
	} else {
		return urlObject.hostname;
	}
}

export function tidyPropertyGroup(url) {
	return new URL(url).searchParams.get('property_lists[]');
}

export const sanitizeUrl = url => {
	try {
		const raw = url.replaceAll('https://', '').replaceAll('http://', '').replaceAll('/', '').replaceAll('www.', '').trim();
		const { hostname } = new URL(`http://${raw}`);
		return hostname;
	} catch (error) {
		return;
	}
};
