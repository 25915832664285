import { tidyUrl } from 'utils/Tidy';
import Score from 'components/shared/Score';
import useSWR from 'swr';
import { Header, Card, Body, BodyRow, Skeleton } from './Components';
import { NoData } from 'components/shared/Table';

const LatestReports = () => {
	// Use SWR Hook
	const { data: reports } = useSWR('/api/v1/dashboard/recent');

	return (
		<Card>
			<Header>
				<i className='fas fa-check-circle text-success'></i> Latest Reports
			</Header>
			<Body>
				{!reports ? (
					<Skeleton />
				) : reports.length > 0 ? (
					reports.map(report => (
						<BodyRow key={report._id} to={`/report/${report._id}`}>
							<Score score={report.qa_checks.score} />
							<div className='text-truncate'>
								<div>{report.site_id}</div>
								<small className='text-secondary'>{tidyUrl(report.site_url)}</small>
							</div>
						</BodyRow>
					))
				) : (
					<NoData />
				)}
			</Body>
		</Card>
	);
};

export default LatestReports;
