import LoadingOverlay from 'components/shared/LoadingOverlay';
import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import PageCountTable from 'components/Audits/PageCountTable';
import { fetcher } from 'utils/Fetch';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import styled from 'styled-components';
import { sanitizeUrl, tidyDateTime } from 'utils/Tidy';
import { useMessage } from 'utils/MessageContext';
import Panel from 'components/shared/Panel';

const Component = styled.div`
	display: grid;
	justify-items: start;
	gap: 1.5rem;
`;

const List = styled.ol`
	margin: 0;
	padding-left: 2rem;

	> li {
		margin: 0.5rem 0;
	}
`;

const PageCount = () => {
	// Hooks
	const { data: siteData, isValidating: swrValidating, isLoading: swrLoading, mutate } = useSWR('/api/v1/audits/page-count/violations');
	const { sites, last_import_date } = { ...siteData };
	const { CSVReader } = useCSVReader();
	const { setMessage } = useMessage();

	// State
	const [isLoading, setIsLoading] = useState(false);

	// Effects
	useEffect(() => {
		swrValidating || swrLoading ? setIsLoading(true) : setIsLoading(false);
	}, [swrValidating, swrLoading]);

	// Handlers
	const handleSfCsv = async results => {
		setIsLoading(true);
		try {
			// Compile SFDC payload
			const { data } = results;
			const payload = data
				.map(site => ({
					url: sanitizeUrl(site['Website URL']),
					page_count: site['Page Count'],
					value_plus_id: site['Value+: ID'],
					value_plus_name: site['Value+: Value+'],
					tier: site['Website Type'],
					stage: site['Stage']
				}))
				.filter(site => site.url && site.value_plus_id && site.value_plus_name && site.tier && site.stage);

			const { modifiedCount } = await fetcher('/api/v1/audits/page-count/sfdc', { method: 'POST', body: JSON.stringify(payload) });
			await mutate();
			setMessage({ variant: 'success', text: `Successfully updated ${modifiedCount} sites.` });
		} catch (error) {
			setMessage({ variant: 'danger', text: `Error importing csv. No sites were updated..` });
		}
		setIsLoading(false);
	};

	return (
		<>
			<PageHeader heading='Page Count Audit' subheading='Monthly audit to ensure site page counts are within their pricing tiers.' />
			<Component>
				<Panel style={{ justifySelf: 'stretch' }}>
					<h5 className='m-0'>Instructions</h5>
					<List>
						<li>
							Export the{' '}
							<a href='https://appfolio.lightning.force.com/lightning/r/Report/00O4U000004vrYRUAY/view' target='_blank' rel='noopener noreferrer'>
								Page Count Reconciliation report
							</a>{' '}
							from SalesForce, using the <strong>Details Only</strong> and <strong>Comma Delimited format</strong> options.
						</li>
						<li>
							Import the downloaded csv file using the <strong>Import SalesForce CSV</strong> button. This is a computationally expensive operation, so please be patient.
						</li>
						<li>Sites that violate their pricing tier will be displayed in the table below. If a site is not listed, it is within its pricing tier, or could not be found.</li>
					</List>
				</Panel>
				<div>
					<CSVReader config={{ header: true }} onUploadAccepted={results => handleSfCsv(results)} noDrag>
						{({ getRootProps }) => (
							<Button {...getRootProps()}>
								Import SalesForce CSV <i className='fas fa-file-import'></i>
							</Button>
						)}
					</CSVReader>
				</div>
				<div>
					Last SalesForce CSV import: <strong>{tidyDateTime(last_import_date)}</strong>
				</div>
				{isLoading ? <LoadingOverlay /> : sites && <PageCountTable sites={sites} />}
			</Component>
		</>
	);
};

export default PageCount;
