import { useEffect } from 'react';
import ProtectedRoute from './components/layout/ProtectedRoute';
import { useMessage } from './utils/MessageContext';
import Login from './components/pages/Login';
import { Routes, Route, useLocation } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { fetcher } from './utils/Fetch';
import ReactGA from 'react-ga4';
import { ErrorBoundary } from 'react-error-boundary';
import Error from 'components/pages/Error';
import { config } from 'config/config';

// On route changes
const usePageChange = () => {
	const location = useLocation();
	const { setMessage } = useMessage();

	// Initialize GA
	useEffect(() => {
		ReactGA.initialize(config.GOOGLE_ANALYTICS_ID);
	}, []);

	// Clear message bar
	useEffect(() => {
		setMessage(null);
	}, [location, setMessage]);
};

export default function App() {
	// Hooks
	const { message, setMessage } = useMessage();
	usePageChange();

	// Effects
	useEffect(() => {
		if (message) {
			const timeout = setTimeout(() => setMessage(null), 5000);
			return () => clearTimeout(timeout);
		}
	}, [message, setMessage]);

	return (
		<ErrorBoundary fallbackRender={Error}>
			<SWRConfig
				value={{
					shouldRetryOnError: false,
					revalidateOnFocus: false,
					fetcher: url => fetcher(url)
				}}>
				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='*' element={<ProtectedRoute />} />
				</Routes>
			</SWRConfig>
		</ErrorBoundary>
	);
}
