import Card from 'components/shared/Card';
import { tidyCapitalize } from 'utils/Tidy';
import styled from 'styled-components';

const Nav = styled.nav`
	display: grid;
`;

const Item = styled.button`
	all: unset;
	display: grid;
	grid-template-columns: 1fr 3rem 3rem;
	padding: 0.5rem 0.75rem;
	border-bottom: 1px solid var(--lightest);
	transition: 0.1s;

	&:last-of-type {
		border-bottom: none;
	}

	&:hover,
	&:focus-visible {
		background: var(--lightest);
	}

	&.active {
		box-shadow: inset 0.25rem 0 0 0 var(--bs-primary);
		color: var(--bs-primary);
		padding-left: 1rem;
	}
`;

const Icon = styled.div`
	text-align: right;
`;

const NavItem = ({ name, warnings, errors, ...props }) => {
	return (
		<Item {...props}>
			<div>{name ? tidyCapitalize(name) : ''}</div>
			<Icon className='text-warning'>
				{warnings > 0 && (
					<>
						<i className='fas fa-exclamation-circle me-1'></i>
						{warnings}
					</>
				)}
			</Icon>
			<Icon className='text-danger'>
				{errors > 0 && (
					<>
						<i className='fas fa-exclamation-circle me-1'></i>
						{errors}
					</>
				)}
			</Icon>
		</Item>
	);
};

const Navigation = ({ report, activePage, setActivePage }) => {
	const { qa_checks, site_pages } = { ...report };
	const { warnings, errors } = { ...qa_checks };

	return (
		<>
			<Card icon={'fas fa-file-medical-alt'} title={'Overviews'} noPadding limitHeight>
				<Nav>
					<NavItem name='Report Overview' warnings={warnings} errors={errors} className={activePage === 'report-overview' ? 'active' : ''} onClick={() => setActivePage('report-overview')} />
				</Nav>
			</Card>
			<Card icon={'fas fa-file-alt'} title={`Pages (${site_pages?.length})`} noPadding limitHeight>
				<Nav>
					{site_pages?.map(page => (
						<NavItem key={page?.uuid} name={page?.title} warnings={page?.qa_checks?.warnings} errors={page?.qa_checks?.errors} className={activePage === page?.uuid ? 'active' : ''} onClick={() => setActivePage(page?.uuid)} />
					))}
				</Nav>
			</Card>
		</>
	);
};

export default Navigation;
