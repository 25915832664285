import { useParams } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import ScoreHistory from './ScoreHistory';
import ClientPublishes from './ClientPublishes';
import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import styled from 'styled-components';
import Card from 'components/shared/Card';
import { Details } from './Details';
import { ReportHistory } from './ReportHistory';

const Component = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5rem;
	align-items: start;

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const Overview = () => {
	// Get plan ID from route params
	const { vertical, id } = useParams();

	// Hooks
	const { data, mutate } = useSWR(`/api/v1/sites/${vertical}/${id}`);

	const { site_domain, reports } = { ...data };
	reports?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

	return !data ? (
		<LoadingOverlay />
	) : (
		<>
			<PageHeader heading='Site Overview' subheading='View details of a specific site including the QA history.' />
			<Component>
				<Card icon='fas fa-home' title={id} subtitle={site_domain} noPadding={true}>
					<Details site_details={data} mutate={mutate} />
				</Card>
				<Card icon='fas fa-table' title='Report History' noPadding={true}>
					<ReportHistory reports={reports} />
				</Card>
				<Card icon='fas fa-chart-line' title='Score History'>
					<ScoreHistory reports={reports} />
				</Card>
				<Card icon='fas fa-cloud-upload-alt' title='Client Publishes'>
					<ClientPublishes site_id={id} />
				</Card>
			</Component>
		</>
	);
};

export default Overview;
