import styled from 'styled-components';
import { Header, Card, Body, BodyRow, Skeleton } from './Components';
import { NoData } from 'components/shared/Table';
import useSWR from 'swr';
import { tidyUrl } from 'utils/Tidy';

const Count = styled.div`
	background: var(--bs-primary);
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
	display: grid;
	place-items: center;
	color: white;
	box-shadow: 0 0 0 4px rgba(0, 123, 199, 0.25);
`;

const MostPublished = () => {
	// Use SWR Hook
	const { data: sites } = useSWR('/api/v1/dashboard/publish');

	return (
		<Card>
			<Header>
				<i className='fas fa-cloud-upload-alt text-primary'></i> Top 10 Client Publishes
			</Header>
			<Body>
				{!sites ? (
					<Skeleton />
				) : sites.length > 0 ? (
					sites.map(site => (
						<BodyRow key={site._id} to={`/site/${site.business_vertical}/${site.site_id}`}>
							<Count>{site.publish_count}</Count>
							<div>
								<div>{site.site_id}</div>
								<small className='text-secondary'>{tidyUrl(site.url)}</small>
							</div>
						</BodyRow>
					))
				) : (
					<NoData />
				)}
			</Body>
		</Card>
	);
};

export default MostPublished;
