import { useNavigate } from 'react-router-dom';
import PageHeader from 'components/shared/PageHeader';
import SiteIdInput from 'components/shared/SiteIdInput';

const FindSite = () => {
	// Hooks
	const navigate = useNavigate();

	// Handlers
	const handleSubmit = ({ site_id, business_vertical }) => {
		navigate(`/site/${business_vertical}/${site_id}`);
	};

	return (
		<>
			<PageHeader heading={'Find a Site'} subheading={'Find the details of a specific site.'} />
			<SiteIdInput submitFunction={handleSubmit} />
		</>
	);
};

export default FindSite;
