import { Col, Row } from 'react-bootstrap';
import Card from 'components/shared/Card';
import QAChart from './QAChart';
import ScoreChart from './ScoreChart';

const ReportOverview = ({ report }) => {
	// Destructure props
	const { qa_checks } = report;

	// QA checks
	const { successes, warnings, errors, total, score } = qa_checks;
	const success_rate = ((successes / total) * 100).toFixed(0);
	const warning_rate = ((warnings / total) * 100).toFixed(0);
	const error_rate = ((errors / total) * 100).toFixed(0);

	return (
		<Card icon={'fas fa-file-medical-alt'} title={'Report Overview'}>
			<Row className='align-items-center'>
				<Col md={6}>
					<div className='text-center'>
						<h4 className='mb-4'>QA Score</h4>
						<QAChart score={score} />
					</div>
				</Col>
				<Col md={6}>
					<Row className='align-items-center my-3'>
						<Col xs={5}>
							<ScoreChart color={'yellowgreen'} score={successes} total={total} />
						</Col>
						<Col xs={7}>
							<p className='text-success mb-0 font-weight-bold'>
								<i className='fas fa-check-circle'></i> Successes:
							</p>
							<p className='text-secondary mb-0'>
								Count: <span className='text-dark'>{successes}</span>
							</p>
							<p className='text-secondary'>
								Rate: <span className='text-dark'>{success_rate}%</span>
							</p>
						</Col>
					</Row>
					<Row className='align-items-center my-3'>
						<Col xs={5}>
							<ScoreChart color={'darkorange'} score={warnings} total={total} />
						</Col>
						<Col xs={7}>
							<p className='text-warning mb-0 font-weight-bold'>
								<i className='fas fa-exclamation-circle'></i> Warnings:
							</p>
							<p className='text-secondary mb-0'>
								Count: <span className='text-dark'>{warnings}</span>
							</p>
							<p className='text-secondary'>
								Rate: <span className='text-dark'>{warning_rate}%</span>
							</p>
						</Col>
					</Row>
					<Row className='align-items-center my-3'>
						<Col xs={5}>
							<ScoreChart color={'#da3134'} score={errors} total={total} />
						</Col>
						<Col xs={7}>
							<p className='text-danger mb-0 font-weight-bold'>
								<i className='fas fa-times-circle'></i> Errors:
							</p>
							<p className='text-secondary mb-0'>
								Count: <span className='text-dark'>{errors}</span>
							</p>
							<p className='text-secondary'>
								Rate: <span className='text-dark'>{error_rate}%</span>
							</p>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
};

export default ReportOverview;
