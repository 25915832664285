import Success from 'components/shared/Success';
import Error from 'components/shared/Error';
import Card from 'components/shared/Card';
import { tidyUrl } from 'utils/Tidy';
import styled from 'styled-components';

const Item = styled.div`
	margin-bottom: 1rem;

	&:last-of-type {
		margin-bottom: 0;
	}
`;

const Name = styled.div`
	color: var(--bs-secondary);
`;

const Value = styled.span`
	color: var(--bs-dark);
`;

const TemplateChecks = ({ page }) => {
	// Destructure data
	const { template_checks } = { ...page };

	// Render functions
	const renderStatus = (name, status) => {
		if (status === 'OK') {
			return <Success content={'OK'} />;
		} else {
			if (name === 'Property Search Widget') {
				return <Error content={'Error'} popover={'Property Search widget should be connected to a page with a Listings v2 widget.'} />;
			}

			if (name === 'Accessibility Statement Email') {
				return <Error content={'Error'} popover={"Accessibility Statement email should be the customer's email address."} />;
			}

			if (name === 'Accessibility Statement Phone') {
				return <Error content={'Error'} popover={"Accessibility Statement phone should be the customer's phone number."} />;
			}
		}
	};

	return (
		<>
			{template_checks && template_checks.length > 0 && (
				<Card icon={'fas fa-bug'} title={'Template Checks'}>
					{template_checks.map((widget, index) => (
						<Item key={index}>
							<Name>
								Name: <Value>{widget.name}</Value>
							</Name>
							{widget.url && (
								<Name>
									Connected URL:{' '}
									<a href={widget.url} target='_blank' rel='noopener noreferrer'>
										{tidyUrl(widget.url)}
									</a>
								</Name>
							)}
							<Name>
								Status: <Value>{renderStatus(widget.name, widget.status)}</Value>
							</Name>
						</Item>
					))}
				</Card>
			)}
		</>
	);
};

export default TemplateChecks;
