import { useLogin } from 'utils/UserContext';
import logo from 'img/appfolio_logo_wh.svg';
import { NavLink } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import styled from 'styled-components';

const Component = styled.aside`
	grid-area: sidebar;
	background: var(--darkest);
	color: white;
	display: grid;
	grid-template-rows: auto 1fr auto;

	@media (max-width: 767px) {
		grid-template-rows: auto;
		grid-auto-flow: column;
		justify-content: center;
		align-items: center;
		overflow-x: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	@media (max-width: 576px) {
		justify-content: start;
	}
`;

const AppInfo = styled.div`
	display: grid;
	justify-content: center;
	text-align: center;
	margin-bottom: 3rem;

	@media (max-width: 767px) {
		display: none;
	}
`;

const Logo = styled.img`
	margin: 4rem auto 1.5rem;
`;

const AppName = styled.h4``;

const LinkIcon = styled.i`
	color: var(--bs-secondary);
	transition: inherit;
	margin-left: 0.75rem;
`;

const NavItem = styled.div`
	color: white;
	display: grid;
	padding: 1rem 1.5rem;
	grid-template-columns: auto 1fr;
	gap: 1.5rem;
	align-items: center;
	transition: 0.1s;
	position: relative;

	&:hover,
	&:focus {
		color: var(--bs-primary);

		${LinkIcon} {
			color: var(--bs-primary);
		}
	}

	@media (max-width: 767px) {
		grid-template-columns: auto;
	}
`;

const NavMenu = styled.nav`
	a {
		text-decoration: none !important;

		&.active ${NavItem} {
			background: var(--bs-primary);
			color: white;
		}
	}

	@media (max-width: 767px) {
		display: grid;
		grid-auto-flow: column;
		justify-content: center;
	}
`;

const ExtLink = styled.a``;

const ItemName = styled.div`
	@media (max-width: 767px) {
		display: none;
	}
`;

const Small = styled.div`
	color: var(--bs-secondary);
	font-size: 0.9rem;
	padding: 0.5rem 1rem;
	border-bottom: 1px solid #fff3;

	@media (max-width: 767px) {
		display: none;
	}
`;

const ItemIcon = styled.i`
	font-size: 1.5rem;
`;

const Logout = styled.div`
	background: var(--bs-dark);
	display: grid;
`;

const LogoutButton = styled.button`
	all: unset;
	color: white;
	display: grid;
	padding: 1rem 1.5rem;
	grid-template-columns: auto 1fr;
	gap: 1.5rem;
	align-items: center;
	transition: 0.1s;

	&:hover,
	&:focus {
		color: var(--bs-primary);
	}

	@media (max-width: 767px) {
		gap: 0;
	}
`;

export default function Sidebar() {
	// User Hooks
	const { user, setUser } = useLogin();

	// On successful logout
	const handleLogout = async () => {
		googleLogout();
		await fetch('/auth/signout');
		setUser(null);
	};

	return (
		<Component>
			<AppInfo>
				<NavLink to='/'>
					<Logo src={logo} alt='AppFolio Logo' width='70' />
				</NavLink>
				<AppName>Website QA Tool</AppName>
			</AppInfo>
			<NavMenu>
				<NavLink to='/dashboard'>
					<NavItem>
						<ItemIcon className='fas fa-chart-bar'></ItemIcon>
						<ItemName>Dashboard</ItemName>
					</NavItem>
				</NavLink>
				<NavLink to='/report/new'>
					<NavItem>
						<ItemIcon className='fas fa-folder-plus'></ItemIcon>
						<ItemName>New QA Report</ItemName>
					</NavItem>
				</NavLink>
				<NavLink to='/site/find'>
					<NavItem>
						<ItemIcon className='fas fa-search'></ItemIcon>
						<ItemName>Find a Site</ItemName>
					</NavItem>
				</NavLink>
				<NavLink to='/listings/find'>
					<NavItem>
						<ItemIcon className='fas fa-database'></ItemIcon>
						<ItemName>Listings Data</ItemName>
					</NavItem>
				</NavLink>
				<NavLink to='/job/queue'>
					<NavItem>
						<ItemIcon className='fas fa-stream'></ItemIcon>
						<ItemName>View Job Queue</ItemName>
					</NavItem>
				</NavLink>
				<NavLink to='/audits/page-count'>
					<NavItem>
						<ItemIcon className='fas fa-file-alt'></ItemIcon>
						<ItemName>Page Count Audit</ItemName>
					</NavItem>
				</NavLink>
				<ExtLink href='https://axe.appfoliowebsites.com' target='_blank' rel='noopener noreferrer'>
					<NavItem>
						<ItemIcon className='fas fa-universal-access'></ItemIcon>
						<ItemName>
							Accessibility Scan <LinkIcon className='fas fa-external-link-alt' />
						</ItemName>
					</NavItem>
				</ExtLink>
				{user.isAdmin && (
					<>
						<Small>Admin</Small>
						<NavLink to='/admin/sites'>
							<NavItem>
								<ItemIcon className='fas fa-tools'></ItemIcon>
								<ItemName>Sites</ItemName>
							</NavItem>
						</NavLink>
						<NavLink to='/admin/logs'>
							<NavItem>
								<ItemIcon className='fas fa-history'></ItemIcon>
								<ItemName>Logs</ItemName>
							</NavItem>
						</NavLink>
					</>
				)}
			</NavMenu>
			<Logout>
				<LogoutButton onClick={() => handleLogout()}>
					<ItemIcon className='fas fa-sign-out-alt' />
					<ItemName>Log out</ItemName>
				</LogoutButton>
			</Logout>
		</Component>
	);
}
