export const options = {
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			backgroundColor: 'var(--darkest)',
			cornerRadius: 4,
			displayColors: false,
			titleFont: {
				family: 'Poppins'
			},
			bodyFont: {
				family: 'Poppins'
			}
		}
	},
	scales: {
		errors: {
			position: 'right',
			min: 0,
			grid: {
				display: false
			}
		},
		score: {
			position: 'left',
			min: 0,
			max: 100,
			grid: {
				display: false
			}
		},
		x: {
			grid: {
				display: false
			}
		}
	}
};
