import { options } from 'utils/charts/Doughnut';
import { Doughnut } from 'react-chartjs-2';
import { getChartColor, getScaleInfo } from 'utils/Colors';
import styled from 'styled-components';

const Component = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	margin: 0 0 1rem;
`;

const Value = styled.h2`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: ${({ $color }) => $color};
	font-size: 4rem;
`;

const QAChart = ({ score }) => {
	// Color variable
	const { color } = getScaleInfo(score);

	// Chart data
	const data = {
		datasets: [
			{
				data: [score, 100 - score],
				backgroundColor: [getChartColor(color), '#eee'],
				borderWidth: 0
			}
		],
		labels: ['Score']
	};

	return (
		<Component>
			<Doughnut data={data} options={options} width={200} height={200} />
			<Value $color={color}>{score}</Value>
		</Component>
	);
};

export default QAChart;
