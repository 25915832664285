import PageHeader from 'components/shared/PageHeader';
import useSWR from 'swr';
import styled from 'styled-components';
import { fetcher } from 'utils/Fetch';
import SiteIdInput from 'components/shared/SiteIdInput';
import { useMessage } from 'utils/MessageContext';
import { Cell, Header, Row, Table } from 'components/shared/Table';
import Vertical from 'components/shared/Vertical';
import { Link } from 'react-router-dom';
import { NoData } from 'components/shared/Table';

const Component = styled.div`
	display: grid;
	gap: 2rem;

	@media (max-width: 576px) {
		gap: 1rem;
	}
`;

const DeleteButton = styled.button`
	all: unset;
	font-size: 1.25rem;
	opacity: 0.75;
	transition: 0.2s;

	&:hover,
	&:focus-visible {
		opacity: 1;
	}
`;

const Sites = () => {
	// Hooks
	const { setMessage } = useMessage();
	const { data: sites, mutate } = useSWR('/api/v1/sites/automated_qa/all');

	const handleAutoQA = async ({ site_id, business_vertical, allow_automated_qa }) => {
		try {
			await fetcher(`/api/v1/sites/automated_qa/${business_vertical}/${site_id}`, { method: 'POST', body: JSON.stringify({ allow_automated_qa }) });
			await mutate();
			setMessage({ variant: 'warning', text: `Automated QA for ${business_vertical} site ${site_id} has been ${allow_automated_qa ? 'enabled' : 'disabled'}` });
		} catch (error) {
			setMessage({ variant: 'danger', text: error.message });
		}
	};

	return (
		<>
			<PageHeader heading={'Sites Admin'} subheading={'Exclude certain sites from being automatically reported by the QA Tool.'} />
			<Component>
				<div>
					<h5>Add site to exclusion list</h5>
					<SiteIdInput submitFunction={handleAutoQA} allow_automated_qa={false} />
				</div>
				<Table>
					<Header $cols='1fr 1fr 7.5rem'>
						<Cell>Site ID</Cell>
						<Cell>Business Vertical</Cell>
						<Cell>Remove</Cell>
					</Header>
					{sites?.length > 0 ? (
						sites?.map(site => (
							<Row key={site._id} $cols='1fr 1fr 7.5rem'>
								<Cell>
									<div>
										<Link to={`/site/${site.business_vertical}/${site._id}`} className='text-dark'>
											{site._id}
										</Link>
									</div>
									<div>
										<Link to={`/site/${site.business_vertical}/${site._id}`} className='text-muted'>
											<small>{site.site_domain || '-'}</small>
										</Link>
									</div>
								</Cell>
								<Cell>
									<Vertical vertical={site.business_vertical} />
								</Cell>
								<Cell>
									<DeleteButton onClick={() => handleAutoQA({ business_vertical: site.business_vertical, site_id: site._id, allow_automated_qa: true })}>
										<i className='fas fa-times-circle text-danger'></i>
									</DeleteButton>
								</Cell>
							</Row>
						))
					) : (
						<NoData />
					)}
				</Table>
			</Component>
		</>
	);
};

export default Sites;
