import styled from 'styled-components';
import { tidyDateTime } from 'utils/Tidy';

const Field = styled.div`
	color: var(--bs-secondary);
`;

const PublishInfo = ({ status, date }) => {
	return status === 'PUBLISHED' ? (
		<>
			<div>
				<Field>Publish Status:</Field>
				<div>
					<i className='fas fa-cloud-upload-alt me-1 text-primary'></i> Published
				</div>
			</div>
			<div>
				<Field>Last Published:</Field>
				<div>
					<i className='fas fa-calendar-check me-1 text-primary'></i> {tidyDateTime(date)}
				</div>
			</div>
		</>
	) : (
		<div>
			<Field>Publish Status:</Field>
			<div>
				<i className='fas fa-cloud-upload-alt me-1 text-danger'></i> Not Published
			</div>
		</div>
	);
};

export default PublishInfo;
