import PageHeader from 'components/shared/PageHeader';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import useSWR from 'swr';
import styled from 'styled-components';
import Score from 'components/shared/Score';
import { tidyUrl, tidyDate, tidyTime } from 'utils/Tidy';
import { Link } from 'react-router-dom';
import Vertical from 'components/shared/Vertical';

const Table = styled.div`
	display: grid;
	background: white;
	justify-self: normal;
	margin-bottom: 2rem;
	overflow: auto;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
`;

const Cell = styled.div`
	padding: 0.5rem 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const Row = styled(Link)`
	display: grid;
	grid-template-columns: 7.5rem 10rem minmax(15rem, 1fr) 20rem 10rem;
	align-items: center;
	border-bottom: 1px solid var(--lightest);
	text-decoration: none !important;
	color: inherit !important;
	transition: 0.2s;

	&:last-of-type {
		border: 0;
	}

	&:hover,
	&:focus {
		z-index: 1;
		box-shadow: rgb(0 0 0 / 18%) 0px 0px 3rem;
	}
`;

const TableHeader = styled.div`
	display: grid;
	grid-template-columns: 7.5rem 10rem minmax(15rem, 1fr) 20rem 10rem;
	background: var(--darkest);
	color: white;
	font-weight: bold;
`;

const SortButton = styled.button`
	display: inline-block;
	all: unset;
	padding: 0.5rem 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const TableBody = styled.div``;

const Error = ({ errors }) => {
	if (errors >= 100) {
		return (
			<span className='font-weight-bold text-danger'>
				<i className='fas fa-times-circle'></i> {errors}
			</span>
		);
	} else if (errors > 50 && errors < 100) {
		return (
			<span className='font-weight-bold text-warning'>
				<i className='fas fa-exclamation-circle'></i> {errors}
			</span>
		);
	} else {
		return (
			<span className='font-weight-bold text-success'>
				<i className='fas fa-check-circle'></i> {errors}
			</span>
		);
	}
};

const Lowest = () => {
	// Hooks
	const { data: reports } = useSWR('/api/v1/dashboard/lowest');

	return (
		<>
			<PageHeader heading={'Lowest Scores'} subheading={'Full report for lowest QA scores & highest error counts.'} />
			{reports && reports?.length > 0 ? (
				<Table>
					<TableHeader>
						<SortButton>Score</SortButton>
						<SortButton>Error Count</SortButton>
						<SortButton>Site</SortButton>
						<SortButton>Business Vertical</SortButton>
						<SortButton>Last QA Report</SortButton>
					</TableHeader>
					<TableBody>
						{reports.slice(0, 100).map(report => (
							<Row key={report._id} to={`/site/${report.business_vertical}/${report._id}`}>
								<Cell>
									<Score score={report.score} />
								</Cell>
								<Cell>
									<Error errors={report.errors} />
								</Cell>
								<Cell>
									<p className='m-0'>{report._id}</p>
									<small className='d-block text-secondary text-truncate'>{tidyUrl(report.site_url)}</small>
								</Cell>
								<Cell>
									<Vertical vertical={report.business_vertical} />
								</Cell>
								<Cell>
									<p className='m-0'>{tidyDate(report.createdAt)}</p>
									<small className='d-block text-secondary text-truncate'>{tidyTime(report.createdAt)}</small>
								</Cell>
							</Row>
						))}
					</TableBody>
				</Table>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default Lowest;
