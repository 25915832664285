import { useEffect } from 'react';
import styled from 'styled-components';

const Component = styled.div`
	position: fixed;
	inset: 0;
	display: grid;
	place-items: center;
	background: #fffe;
	z-index: 999;
	backdrop-filter: blur(0.25rem);
`;

const Spinner = styled.div`
	width: 5rem;
	height: 5rem;
`;

const LoadingOverlay = ({ ...props }) => {
	// Effects
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => (document.body.style.overflow = 'auto');
	});

	return (
		<Component {...props}>
			<Spinner className='spinner-border text-primary' />
		</Component>
	);
};

export default LoadingOverlay;
