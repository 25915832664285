export const options = {
	maintainAspectRatio: false,
	datasets: {
		bar: {
			backgroundColor: 'var(--bs-primary)',
			barPercentage: 1
		}
	},
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			backgroundColor: 'var(--darkest)',
			cornerRadius: 4,
			displayColors: false,
			titleFont: {
				family: 'Poppins'
			},
			bodyFont: {
				family: 'Poppins'
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false,
				borderColor: '#eee'
			},
			ticks: {
				font: {
					family: 'Poppins'
				}
			}
		},
		y: {
			grid: {
				display: false,
				borderColor: '#eee'
			},
			ticks: {
				font: {
					family: 'Poppins'
				}
			}
		}
	}
};
