import { useParams } from 'react-router-dom';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import MainInfo from './MainInfo';
import Navigation from './Navigation';
import ReportOverview from './Checks/ReportOverview';
import PageSummary from './Checks/PageSummary';
import Images from './Checks/Images';
import Icons from './Checks/Icons';
import Headings from './Checks/Headings';
import CustomWidgets from './Checks/CustomWidgets';
import Collection from './Checks/Collection';
import Forms from './Checks/Forms';
import PageHeader from 'components/shared/PageHeader';
import TemplateChecks from './Checks/TemplateChecks';
import useSWR from 'swr';
import SocialIcons from './Checks/SocialIcons';
import ProductTier from './Checks/ProductTier';
import AudioEye from './Checks/AudioEye';
import styled from 'styled-components';
import { Fragment, useState } from 'react';

const Component = styled.div`
	display: grid;
	grid-template-columns: 20rem 1fr;
	gap: 1.5rem;
	align-items: start;

	@media (max-width: 768px) {
		grid-template-columns: 100%;
	}
`;

const Slot = styled.div`
	display: grid;
	gap: 1.5rem;
	align-items: start;
`;

const Slot1 = styled(Slot)`
	grid-column: 1 / 4;

	@media (max-width: 768px) {
		grid-column: 1 / 2;
	}
`;

const Report = () => {
	// Hooks
	const { id } = useParams();
	const { data: report, error } = useSWR(`/api/v1/reports/${id}`);
	if (error) throw error;
	const { business_vertical, collection_details, product_details, audio_eye, site_id, site_url } = { ...report };

	// State
	const [activePage, setActivePage] = useState('report-overview');
	const page_data = report?.site_pages?.find(page => page.uuid === activePage);

	return report ? (
		<>
			<PageHeader heading={'QA Report'} subheading={'Details of the QA report for a specific site.'} />
			<Component>
				<Slot1>
					<MainInfo report={report} />
				</Slot1>
				<Slot>
					<Navigation report={report} activePage={activePage} setActivePage={setActivePage} />
				</Slot>
				<Slot>
					{activePage === 'report-overview' ? (
						<>
							<ReportOverview report={report} />
							{audio_eye && <AudioEye audio_eye={audio_eye} />}
							{business_vertical === 'APM' && (
								<>
									{product_details && <ProductTier product_details={product_details} />}
									{collection_details && <Collection collection_details={collection_details} />}
								</>
							)}
						</>
					) : (
						<Fragment key={page_data.uuid}>
							<PageSummary site_id={site_id} site_url={site_url} business_vertical={business_vertical} page={page_data} />
							<SocialIcons page={page_data} />
							<Images page={page_data} />
							<Icons icons={page_data.icons} />
							<TemplateChecks page={page_data} />
							<CustomWidgets page={page_data} />
							<Headings page={page_data} />
							<Forms page={page_data} />
						</Fragment>
					)}
				</Slot>
			</Component>
		</>
	) : (
		<LoadingOverlay />
	);
};

export default Report;
