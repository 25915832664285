import Success from 'components/shared/Success';
import Warning from 'components/shared/Warning';
import Error from 'components/shared/Error';
import Card from 'components/shared/Card';
import styled from 'styled-components';

const Component = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const HeadingItem = styled.div``;

const Headings = ({ page }) => {
	// Destructure data
	const { headings } = { ...page };

	// Render functions
	const renderContent = heading => {
		if (heading) {
			return <Success content={heading} />;
		} else {
			return <Error content={'Not found'} popover={'Page should have an H1 heading.'} />;
		}
	};

	const renderWhiteText = whiteTextCount => {
		if (whiteTextCount > 0) {
			return <Warning content={whiteTextCount} popover={'Check that white heading text is visible and not on a white background.'} />;
		} else {
			return;
		}
	};

	const renderCount = heading => {
		const { name, count, content } = heading;
		if (name === 'H1') {
			if (count !== 1 || !content) {
				return <Error content={count} popover={'Page should have exactly one H1 heading.'} />;
			} else {
				return <Success content={count} />;
			}
		}
	};

	return (
		<>
			{headings && headings.length > 0 && (
				<Card icon={'fas fa-heading'} title={'Headings'}>
					<Component>
						{headings
							.filter(heading => heading.name === 'H1' || (heading.count > 0 && heading.white_text > 0))
							.map((heading, index) => (
								<HeadingItem key={index}>
									{heading.name === 'H1' ? (
										<>
											<h6 className='mb-1'>{heading.name}</h6>
											<p className='m-0'>
												<span className='text-secondary'>Content: </span>
												{renderContent(heading.content)}
											</p>
											{heading.count > 0 && (
												<p className='m-0'>
													<span className='text-secondary'>Count: </span>
													{renderCount(heading)}
												</p>
											)}
											{heading.white_text > 0 && (
												<p className='m-0'>
													<span className='text-secondary'>White Text: </span>
													{renderWhiteText(heading.white_text)}
												</p>
											)}
										</>
									) : (
										<>
											<h6 className='mb-1'>{heading.name}</h6>
											{heading.count > 0 && (
												<p className='m-0'>
													<span className='text-secondary'>Count: </span>
													{heading.count}
												</p>
											)}
											{heading.white_text > 0 && (
												<p className='m-0'>
													<span className='text-secondary'>White Text: </span>
													{renderWhiteText(heading.white_text)}
												</p>
											)}
										</>
									)}
								</HeadingItem>
							))}
					</Component>
				</Card>
			)}
		</>
	);
};

export default Headings;
