import { useState } from 'react';
import { Button, Accordion, AccordionCollapse } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Confirmation from 'components/shared/Confirmation';
import Card from 'components/shared/Card';
import PageHeader from 'components/shared/PageHeader';
import Comparison from './Comparison';
import Success from 'components/shared/Success';
import Warning from 'components/shared/Warning';
import Error from 'components/shared/Error';
import styled from 'styled-components';
import LocationData from './LocationData';
import { fetcher } from 'utils/Fetch';
import { useMessage } from 'utils/MessageContext';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import useSWR from 'swr';
import Toggle from 'components/shared/Toggle';

const Component = styled.div`
	display: grid;
	align-content: start;
	gap: 1.5rem;
`;

const DataWrapper = styled.div`
	display: grid;
	gap: 1rem;
`;

const Data = styled(Accordion)`
	background: var(--lightest);
	padding: 0.75rem 1rem;
	border-radius: 0.5rem;
	transition: 0.2s;

	&:hover,
	&:focus-visible {
		background: var(--bs-light);
	}
`;

const ApiWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1.5rem;

	@media (max-width: 991px) {
		grid-template-columns: 100%;
	}
`;

const Info = styled.code`
	display: block;
	overflow: hidden;
	white-space: pre;
`;

const CardInner = styled.div`
	display: grid;
	align-content: start;
	gap: 1rem;
`;

const Label = styled.div`
	color: var(--bs-secondary);
	font-size: 0.9rem;
`;

const SiteInfoWrapper = styled.div`
	display: grid;
	align-content: start;
	gap: 1.5rem;
`;

const Listings = () => {
	// Hooks
	const { id } = useParams();
	const { data: siteInfo, mutate } = useSWR(`/api/v1/sites/collection/${id}`);
	const { setMessage } = useMessage();

	// State
	const [isLoading, setIsLoading] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);

	// Handlers
	const handleRefreshData = async () => {
		setShowConfirmation(false);
		setIsLoading(true);
		try {
			await fetcher(`/api/v1/sites/collection/refresh/${id}`, { method: 'POST' });
			await mutate();
			setMessage({ variant: 'success', text: `Successfully refreshed collection data for site ${id}.` });
		} catch (error) {
			setMessage({ variant: 'danger', text: `Error refreshing collection data for site ${id}.` });
		}
		setIsLoading(false);
	};

	// Render functions
	const renderDescription = name => {
		if (name === 'AppFolio') {
			return (
				<>
					<div>
						<Label>Description:</Label>
						<div>This is listings data coming directly from the customer's AppFolio database when a unit is posted to the website.</div>
					</div>
					<div>
						<Label>Refresh interval:</Label>
						<div>~15 minutes</div>
					</div>
				</>
			);
		}

		if (name === 'Website') {
			return (
				<>
					<div>
						<Label>Description:</Label>
						<div>This is listings data as stored on the website itself. It powers the listings widgets and generates the listings detail pages.</div>
					</div>
					<div>
						<Label>Refresh interval:</Label>
						<div>~2 hours</div>
					</div>
				</>
			);
		}
	};

	const renderStatus = error => {
		if (!error) {
			return <Success content={'Found'} />;
		} else {
			return <Error content={'Not found'} popover={'This collection was not found.'} />;
		}
	};

	const renderTotal = total => {
		if (total > 0) {
			return <Success content={total} />;
		} else {
			return <Warning content={total} popover={'This collection does not contain any listings.'} />;
		}
	};

	return (
		<>
			<Confirmation show={showConfirmation} setShow={setShowConfirmation} message={`refresh site ${id} with listings data from ${siteInfo?.endpoint?.database}`} confirmFunction={handleRefreshData} />
			<PageHeader heading={'Listings Data'} subheading={"Inspect the health of a site's listings by comparing site collection & API data."} />
			{isLoading || !siteInfo ? (
				<LoadingOverlay />
			) : (
				<Component>
					<SiteInfoWrapper>
						<ApiWrapper>
							<Card icon='fas fa-info-circle' title='Site Info' className='m-0'>
								<CardInner>
									<div>
										<Label>URL:</Label>
										<a href={`http://${siteInfo.url}`} target='_blank' rel='noopener noreferrer'>
											{siteInfo.url}
										</a>
									</div>
									<div>
										<Label>Database:</Label>
										<a href={`http://${siteInfo.endpoint.database}/listings`} target='_blank' rel='noopener noreferrer'>
											{siteInfo.endpoint.database}
										</a>
									</div>
									<div>
										<Label>Property Group:</Label>
										<div>{siteInfo.endpoint.propertyGroup ?? 'None'}</div>
									</div>
									<Button onClick={() => setShowConfirmation(true)}>
										Sync Data <i className='fas fa-sync-alt'></i>
									</Button>
								</CardInner>
							</Card>
							{siteInfo.collections.map(collection => (
								<Card key={collection.name} icon='fas fa-database' title={`${collection.name} API`} className='m-0'>
									<CardInner>
										{renderDescription(collection.name)}
										<div>
											<Label>Status:</Label>
											<div>{renderStatus(collection.error)}</div>
										</div>
										{!collection.error && (
											<>
												<div>
													<Label>Listings:</Label>
													<div>{renderTotal(collection.total)}</div>
												</div>
												<Comparison comparisons={collection.comparisons} />
											</>
										)}
									</CardInner>
								</Card>
							))}
						</ApiWrapper>
						{siteInfo.diff?.data?.length > 0 && (
							<Card icon='fas fa-info-circle' title='Data Info' className='m-0'>
								<CardInner>
									<div>
										<Label>Description:</Label>
										<div>Based on the differences in the listings data between these APIs, we have determined the following details. You can click each listing to view its raw data.</div>
									</div>
									<DataWrapper>
										{siteInfo.diff.data.map((item, i) => (
											<Data key={item.page_item_url}>
												<Toggle eventKey={i + 1}>
													<div>
														<div>
															{item.data.full_address} <i className='fas fa-angle-down ms-1'></i>
														</div>
														<small className='text-secondary'>ID: {item.page_item_url}</small>
													</div>
												</Toggle>
												<AccordionCollapse eventKey={i + 1}>
													<Info>{JSON.stringify(item.data, null, 2)}</Info>
												</AccordionCollapse>
											</Data>
										))}
									</DataWrapper>
								</CardInner>
							</Card>
						)}
						{siteInfo.collections[0].listings.length > 0 && <LocationData collections={siteInfo.collections[0].listings} />}
					</SiteInfoWrapper>
				</Component>
			)}
		</>
	);
};

export default Listings;
