import Score from 'components/shared/Score';
import { Cell, NoData, RowLink } from 'components/shared/Table';
import styled from 'styled-components';
import { tidyDate, tidyTime } from 'utils/Tidy';

const Component = styled.div`
	max-height: 31.25rem;
`;

export const ReportHistory = ({ reports }) => {
	return (
		<Component>
			{reports.length > 0 ? (
				reports.map(report => (
					<RowLink key={report._id} to={`/report/${report._id}`} $cols='auto 1fr'>
						<Cell>
							<Score score={report.qa_checks.score} />
						</Cell>
						<Cell>
							{tidyDate(report.createdAt)}
							<small className='d-block text-muted'>{tidyTime(report.createdAt)}</small>
						</Cell>
					</RowLink>
				))
			) : (
				<NoData />
			)}
		</Component>
	);
};
