import styled from 'styled-components';

const Component = styled.div`
	background: white;
	border-radius: 0.5rem;
	padding: 1.5rem;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 0.125rem 0.25rem;
	display: grid;
	gap: 0.5rem;
`;

const Panel = ({ ...props }) => {
	return <Component {...props} />;
};

export default Panel;
