import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

// Enumerate dates
export function getDates(numberOfDays) {
	const firstDate = dayjs()
		.utc()
		.subtract(numberOfDays - 1, 'day')
		.format('MM/DD/YYYY');
	const lastDate = dayjs().utc();

	const dates = [];
	let currentDate = firstDate;

	while (dayjs(currentDate).utc().isBefore(dayjs(lastDate).utc())) {
		dates.push(currentDate);
		currentDate = dayjs(currentDate).utc().add(1, 'days').format('MM/DD/YYYY');
	}
	return dates;
}
