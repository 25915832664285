export const options = {
	events: [],
	maintainAspectRatio: false,
	cutout: '80%',
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			enabled: false
		}
	}
};
