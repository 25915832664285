import { Cell, Header, Row, Table } from 'components/shared/Table';
import { Link } from 'react-router-dom';

const PageCountTable = ({ sites }) => {
	return (
		<Table>
			<Header $cols='2.5rem minmax(20rem, 2fr) repeat(4, minmax(10rem, 1fr))'>
				<Cell></Cell>
				<Cell>Site ID / URL</Cell>
				<Cell>
					Page Count <i className='fas fa-angle-down text-secondary ms-2'></i>
				</Cell>
				<Cell>Value+</Cell>
				<Cell>Tier</Cell>
				<Cell>Stage</Cell>
			</Header>
			{sites.map(({ _id, duda, sfdc, site_domain }) => (
				<Row key={_id} $cols='2.5rem minmax(20rem, 2fr) repeat(4, minmax(10rem, 1fr))'>
					<i className='fas fa-times-circle text-danger mx-3' />
					<Cell>
						<a href={`http://${site_domain}`} target='_blank' rel='noreferrer'>
							{site_domain}
						</a>
						<small className='d-block'>
							<Link className='text-secondary' to={`/site/APM/${_id}`}>
								{_id}
							</Link>
						</small>
					</Cell>

					<Cell>{duda.total_page_count}</Cell>
					<Cell>
						<a href={`https://appfolio.lightning.force.com/lightning/r/ValuePlus__c/${sfdc?.value_plus_id}/view`} target='_blank' rel='noreferrer'>
							{sfdc?.value_plus_name}
						</a>
					</Cell>
					<Cell>{sfdc?.tier}</Cell>
					<Cell>{sfdc?.stage}</Cell>
				</Row>
			))}
		</Table>
	);
};

export default PageCountTable;
