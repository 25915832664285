import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { tidyUrl } from 'utils/Tidy';
import { Header, Card, Body, BodyRow, Skeleton } from './Components';
import { NoData } from 'components/shared/Table';
import Score from 'components/shared/Score';
import useSWR from 'swr';

const LowestScore = () => {
	// Use SWR Hook
	const { data: reports } = useSWR('/api/v1/dashboard/lowest');

	return (
		<Card>
			<Header>
				<i className='fas fa-exclamation-circle text-warning'></i> Lowest Reports
				<Dropdown className='ms-auto'>
					<Dropdown.Toggle variant='link' className='default-style px-2 py-0'>
						<i className='fas fa-ellipsis-v text-secondary'></i>
					</Dropdown.Toggle>
					<Dropdown.Menu className='p-0 shadow-sm'>
						<Dropdown.Item as={Link} to='/dashboard/lowest' href='/dashboard/lowest'>
							<i className='fas fa-search me-1'></i> View full report
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Header>
			<Body>
				{!reports ? (
					<Skeleton />
				) : reports.length > 0 ? (
					reports.slice(0, 10).map(report => (
						<BodyRow key={report._id} to={`/site/${report.business_vertical}/${report._id}`}>
							<Score score={report.score} />
							<div className='text-truncate'>
								<div>{report._id}</div>
								<small className='text-secondary'>{tidyUrl(report.site_url)}</small>
							</div>
						</BodyRow>
					))
				) : (
					<NoData />
				)}
			</Body>
		</Card>
	);
};

export default LowestScore;
