import Card from 'components/shared/Card';
import styled from 'styled-components';

import Success from 'components/shared/Success';
import Warning from 'components/shared/Warning';

const Field = styled.div`
	margin-bottom: 1rem;

	&:last-of-type {
		margin: 0;
	}
`;

const Name = styled.div`
	color: var(--bs-secondary);
	margin-bottom: 0.25rem;
`;

const Value = styled.div`
	display: grid;
	grid-template-columns: 2rem 1fr;
	gap: 0.75rem;
	align-items: center;

	> i {
		font-size: 2rem;
		text-align: center;
	}
`;

const Image = styled.img`
	width: 2rem;
	height: 2rem;
	object-fit: contain;
`;

const SocialIcons = ({ page }) => {
	// Props
	const { social_icons } = { ...page };
	const { apple_icon, favicon, open_graph_image } = { ...social_icons };

	// Render
	return social_icons ? (
		<Card title='Social Icons' icon='fas fa-share-alt'>
			<Field>
				<Name>Favicon:</Name>
				<Value>
					{favicon ? <Image src={favicon} alt='Favicon' /> : <i className='fas fa-file-image'></i>}
					{favicon && favicon !== 'https://static.cdn-website.com/runtime/favicon_d1_res.ico' ? <Success content={favicon} /> : <Warning content={favicon} popover='Site should have a custom favicon.' />}
				</Value>
			</Field>
			<Field>
				<Name>Apple Icon:</Name>
				<Value>
					{apple_icon ? <Image src={apple_icon} alt='Apple Icon' /> : <i className='fas fa-file-image'></i>}
					{apple_icon ? <Success content={apple_icon} /> : <Warning content='Not found' popover='Site should have a custom Apple app icon.' />}
				</Value>
			</Field>
			<Field>
				<Name>Open Graph Image:</Name>
				<Value>
					{open_graph_image ? <Image src={open_graph_image} alt='Open Graph Image' /> : <i className='fas fa-file-image'></i>}
					{open_graph_image ? <Success content={open_graph_image} /> : <Warning content='Not found' popover='Site should have a Open Graph image.' />}
				</Value>
			</Field>
		</Card>
	) : null;
};

export default SocialIcons;
