import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { options } from 'utils/charts/Line';
import { tidyDate } from 'utils/Tidy';
import { getChartColor } from 'utils/Colors';

const ScoreHistory = ({ reports }) => {
	// Hooks
	const navigate = useNavigate();

	// Data
	const labels = reports.map(report => tidyDate(report.createdAt));
	const scores = reports.map(report => report.qa_checks.score);
	const errors = reports.map(report => report.qa_checks.errors);

	const data = {
		labels,
		datasets: [
			{
				label: 'Score',
				data: scores,
				yAxisID: 'score',
				borderColor: getChartColor('primary'),
				pointBackgroundColor: getChartColor('primary')
			},
			{
				label: 'Errors',
				data: errors,
				yAxisID: 'errors',
				borderColor: getChartColor('danger'),
				pointBackgroundColor: getChartColor('danger')
			}
		]
	};

	// Refs
	const chartRef = useRef(null);

	// Handlers
	const handlePointClick = e => {
		const element = getElementAtEvent(chartRef.current, e);
		const { index } = { ...element[0] };
		const { _id } = { ...reports[index] };
		if (_id) navigate(`/report/${_id}`);
	};

	return <Line ref={chartRef} data={data} options={options} height={400} width={null} onClick={handlePointClick} />;
};

export default ScoreHistory;
