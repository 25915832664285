const Status = ({ status }) => {
	if (status) {
		if (status === 'Queued') {
			return (
				<div>
					<i className='fas fa-pause-circle text-secondary me-1'></i> {status}
				</div>
			);
		}
		if (status === 'Processing') {
			return (
				<div>
					<i className='fas fa-play-circle text-primary me-1'></i> {status}
				</div>
			);
		}
		if (status === 'Completed') {
			return (
				<div>
					<i className='fas fa-check-circle text-success me-1'></i> {status}
				</div>
			);
		}
		if (status === 'Failed') {
			return (
				<div>
					<i className='fas fa-times-circle text-danger me-1'></i> {status}
				</div>
			);
		}
	} else return null;
};

export default Status;
