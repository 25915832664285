import Success from 'components/shared/Success';
import Error from 'components/shared/Error';
import styled from 'styled-components';

const Label = styled.div`
	color: var(--bs-secondary);
	font-size: 0.9rem;
`;

const Comparison = ({ comparisons }) => {
	// Render functions
	const renderCompare = status => {
		if (status) {
			return <Success content={'Synced'} />;
		} else {
			return <Error content={'Not synced'} popover={'Listings data is different compared to this source.'} />;
		}
	};

	return (
		<div>
			{comparisons.map(dataset => (
				<div key={dataset.name}>
					<Label>Sync to {dataset.name} API:</Label>
					<div>{renderCompare(dataset.synced)}</div>
				</div>
			))}
		</div>
	);
};

export default Comparison;
