import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import styled from 'styled-components';

const Component = styled.div`
	background: var(--bs-light);
	height: 50rem;
	display: grid;
	justify-content: stretch;

	@media (max-width: 991px) {
		height: 40vh;
	}
`;

const Map = ({ activeListing, googleMapsApiKey }) => {
	// Props
	const { latitude, longitude } = { ...activeListing?.data?.location?.geo };

	// Hooks
	const { isLoaded } = useJsApiLoader({ googleMapsApiKey });

	// Config
	const center = { lat: parseFloat(latitude), lng: parseFloat(longitude) };

	return (
		<Component>
			{activeListing && isLoaded && (
				<GoogleMap center={center} zoom={15} clickableIcons={false}>
					<Marker position={center} />
				</GoogleMap>
			)}
		</Component>
	);
};

export default Map;
