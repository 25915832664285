import styled from 'styled-components';

const Component = styled.div`
	background: white;
	position: relative;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	overflow: hidden;
`;

const Header = styled.div`
	background: var(--darkest);
	color: white;
	border-radius: 0.5rem 0.5rem 0 0;
	padding: 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const Title = styled.h5`
	margin: 0;
`;

const Icon = styled(Title)`
	color: var(--bs-secondary);
`;

const Subtitle = styled.p`
	margin: 0;
	color: var(--bs-secondary);
`;

const Content = styled.div`
	padding: ${({ $noPadding }) => (!$noPadding ? '1rem' : 0)};
	max-height: ${({ $limitHeight }) => ($limitHeight ? '50rem' : 'none')};
	overflow-y: auto;
	overflow-x: hidden;

	& p:last-child {
		margin-bottom: 0;
		padding-bottom: 0 !important;
	}

	& > *:last-child {
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;
	}

	@media (max-width: 767px) {
		max-height: none;
	}
`;

const Card = ({ icon, title, subtitle, noPadding, limitHeight, children, ...props }) => {
	return (
		<Component {...props}>
			<Header>
				<Icon>
					<i className={icon}></i>
				</Icon>
				<div>
					<Title>{title}</Title>
					{subtitle && <Subtitle>{subtitle}</Subtitle>}
				</div>
			</Header>
			<Content $noPadding={noPadding} $limitHeight={limitHeight}>
				{children}
			</Content>
		</Component>
	);
};

export default Card;
