const PageHeader = ({ heading, subheading }) => {
	return (
		<div className='mb-md-5'>
			<h1>{heading}</h1>
			<p className='text-secondary'>{subheading}</p>
		</div>
	);
};

export default PageHeader;
