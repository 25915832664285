import { Accordion, AccordionCollapse } from 'react-bootstrap';
import Card from 'components/shared/Card';
import Success from 'components/shared/Success';
import Error from 'components/shared/Error';
import styled from 'styled-components';
import Toggle from 'components/shared/Toggle';

const Component = styled.div`
	display: grid;
	gap: 1rem;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
	gap: 1rem;
`;

const Overlay = styled.div`
	position: absolute;
	width: 100%;
	aspect-ratio: 4 / 3;
	background: #0009;
	opacity: 0;
	visibility: hidden;
	transition: 0.2s;
	display: grid;
	place-items: center;
	color: white;
	text-align: center;
`;

const Alt = styled.div`
	padding: 0.75rem;
	line-height: 1.25;
	background: white;
	transition: 0.1s;
	background: var(--lightest);
`;

const ImageLink = styled.a`
	background: var(--lightest);
	transition: 0.1s;
	overflow: hidden;
	text-decoration: none !important;
	position: relative;
	border-radius: 0.5rem;
	border: 1px solid var(--lightest);

	&:hover ${Overlay} {
		opacity: 1;
		visibility: visible;
	}
`;

const Image = styled.img`
	width: 100%;
	aspect-ratio: 4 / 3;
	object-fit: cover;
	object-position: center top;
`;

const images = ({ page }) => {
	// Destructure props
	const { images } = { ...page };
	const imageErrors = images?.filter(image => !image.alt_text || !image.alt_text === 'Default alt text');
	const imageSuccesses = images?.filter(image => !imageErrors.includes(image));

	// Render functions
	const renderAltText = alt => {
		if (alt && alt !== 'Default alt text') {
			return <Success content={alt} />;
		} else {
			return <Error content={'Not found'} popover={'Each image should have descriptive alt text that is not placeholder text.'} />;
		}
	};

	return (
		<>
			{images?.length > 0 && (
				<Card icon={'fas fa-image'} title={'Images'}>
					<Component>
						{imageErrors?.length > 0 && (
							<Wrapper>
								{imageErrors?.map((image, index) => (
									<ImageLink key={index} title={image.alt_text} href={image.src} target='_blank' rel='noopener noreferrer'>
										<Overlay>
											<div>
												<i className='fas fa-external-link-alt me-1'></i> View full size image
											</div>
										</Overlay>
										<Image src={image.src.replace('1920w', '300w')} alt={image.alt_text} loading='lazy' />
										<Alt>
											<p className='text-secondary m-0'>Alt Text:</p>
											<p className='text-nowrap text-truncate m-0'>{renderAltText(image.alt_text)}</p>
										</Alt>
									</ImageLink>
								))}
							</Wrapper>
						)}
						{imageSuccesses?.length > 0 && (
							<Accordion>
								<Toggle eventKey='0'>
									Show gallery of <Success content={imageSuccesses.length} /> image{imageSuccesses.length > 1 && 's'} with descriptive alt text. <i className='fas fa-angle-down text-secondary'></i>
								</Toggle>
								<AccordionCollapse eventKey='0' className='mt-2'>
									<Wrapper>
										{imageSuccesses.map((image, index) => (
											<ImageLink key={index} title={image.alt_text} href={image.src} target='_blank' rel='noopener noreferrer'>
												<Overlay>
													<div>
														<i className='fas fa-external-link-alt me-1'></i> View full size image
													</div>
												</Overlay>
												<Image src={image.src} alt={image.alt_text} loading='lazy' />
												<Alt>
													<p className='text-secondary m-0'>Alt Text:</p>
													<p className='text-nowrap text-truncate m-0'>{renderAltText(image.alt_text)}</p>
												</Alt>
											</ImageLink>
										))}
									</Wrapper>
								</AccordionCollapse>
							</Accordion>
						)}
					</Component>
				</Card>
			)}
		</>
	);
};

export default images;
