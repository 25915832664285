// Return color hex code based on score
export const getScaleInfo = score => {
	// Define color based on score
	if (score <= 79) {
		return {
			color: `var(--bs-danger)`,
			icon: 'fas fa-times-circle'
		};
	} else if (score >= 80 && score <= 89) {
		return {
			color: `var(--bs-warning)`,
			icon: 'fas fa-exclamation-circle'
		};
	} else if (score >= 90) {
		return {
			color: `var(--bs-success)`,
			icon: 'fas fa-check-circle'
		};
	}
};

export const getChartColor = color => {
	const name = color.replaceAll('var(', '').replaceAll(')', '').replaceAll('--bs-', '');
	const wrappedName = `--bs-${name}`;
	return getComputedStyle(document.documentElement).getPropertyValue(wrappedName);
};

// Return color hex code based on score
export const getTotalColor = score => {
	// Define color based on score
	if (score <= 69) {
		return `var(--bs-danger)`;
	} else if (score >= 70 && score <= 89) {
		return `var(--bs-warning)`;
	} else if (score >= 90) {
		return `var(--bs-success)`;
	}
};
