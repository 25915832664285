import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { tidyCapitalize, tidyUrl } from 'utils/Tidy';
import { MetaTitle, MetaDescription, MetaRobots } from 'utils/MetaChecks';
import Card from 'components/shared/Card';

const Summary = ({ site_id, site_url, business_vertical, page }) => {
	// Destructure props
	const { path, seo, title } = page;
	const { title: seo_title, description, no_index } = seo;

	return (
		<Card icon={'fas fa-info-circle'} title={'Page Summary'}>
			<p className='text-secondary m-0'>Page Name:</p>
			<p>{title ? tidyCapitalize(title) : ''}</p>
			<p className='text-secondary m-0'>Page URL:</p>
			<p>
				<a href={`${site_url}/${path}`} target='_blank' rel='noopener noreferrer'>
					{tidyUrl(`${site_url}/${path}`)}
				</a>
			</p>
			<p className='text-secondary m-0'>Meta Title:</p>
			<p>{MetaTitle(seo_title)}</p>
			<p className='text-secondary m-0'>Meta Description:</p>
			<p>{MetaDescription(description)}</p>
			<Button as={Link} to={`/edit/meta/${business_vertical}/${site_id}`} variant='primary' className='mb-3'>
				Edit Meta Info <i className='fas fa-edit'></i>
			</Button>
			<p className='text-secondary m-0'>Meta Robots:</p>
			<p>{MetaRobots(no_index)}</p>
		</Card>
	);
};

export default Summary;
