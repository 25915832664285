import React from 'react';
import styled from 'styled-components';
import { useTemplates } from 'utils/Templates';

const Field = styled.div`
	color: var(--bs-secondary);
`;

const TemplateInfo = ({ template }) => {
	const { getTemplate } = useTemplates();
	const found = getTemplate(template);
	const { name: templateName } = { ...found };

	return (
		templateName && (
			<div>
				<Field>Template:</Field>
				<div>
					<i className='fas fa-folder-open text-primary me-2'></i>
					{templateName}
				</div>
			</div>
		)
	);
};

export default TemplateInfo;
