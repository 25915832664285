import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Warning = ({ content, popover }) => {
	return (
		<span className='text-warning'>
			{content}
			<OverlayTrigger placement='top' overlay={<Tooltip className='warning'>{popover}</Tooltip>}>
				<i className='fas fa-exclamation-circle ms-1'></i>
			</OverlayTrigger>
		</span>
	);
};

export default Warning;
