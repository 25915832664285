import { options } from 'utils/charts/Doughnut';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';

const Component = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
`;

const Value = styled.h2`
	font-size: 1.75rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: ${({ $color }) => $color};
`;

const ScoreChart = ({ color, score, total }) => {
	// Chart data
	const data = {
		datasets: [
			{
				data: [score, total - score],
				backgroundColor: [color, '#eee'],
				borderWidth: 0
			}
		],
		labels: ['Score']
	};

	return (
		<Component>
			<Doughnut data={data} options={options} width={75} height={75} />
			<Value $color={color}>{score}</Value>
		</Component>
	);
};

export default ScoreChart;
