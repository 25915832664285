import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Component = styled.div`
	display: grid;
	color: var(--bs-danger);
	place-items: center;
	align-self: start;
	padding: 4rem 2rem;
	line-height: 1;
	gap: 2rem;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	grid-template-columns: auto 1fr;
	gap: 2rem;

	@media (max-width: 576px) {
		flex-direction: column;
		gap: 0;
	}
`;

const Icon = styled.div`
	font-size: 7rem;
`;

const Title = styled.h1`
	margin: 0;
	font-size: 5rem;
`;

const Text = styled.div`
	line-height: 1.25;

	@media (max-width: 576px) {
		text-align: center;
	}
`;

export default function Error({ error, resetErrorBoundary, ...props }) {
	// Hooks
	const navigate = useNavigate();

	// Handlers
	const handleClick = () => {
		resetErrorBoundary();
		navigate('/');
	};

	return (
		<Component {...props}>
			<Content>
				<Icon>
					<i className='fas fa-exclamation-triangle'></i>
				</Icon>
				<Text>
					<Title>OOPS</Title>
					<strong>Something went wrong</strong>
					<small className='d-block'>{error.message}</small>
				</Text>
			</Content>
			<Button variant='secondary' onClick={() => handleClick()}>
				Go Home
				<i className='fas fa-home'></i>
			</Button>
		</Component>
	);
}
