import { useState, useEffect } from 'react';
import { Form, Button, ProgressBar } from 'react-bootstrap';
import { MetaDescription } from 'utils/MetaChecks';
import { fetcher } from 'utils/Fetch';
import styled from 'styled-components';
import { useMessage } from 'utils/MessageContext';

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 2rem;
	gap: 0.5rem;
`;

const Buttons = styled.div``;

const CustomButton = styled(Button)`
	display: block;
	background: 0;
	min-width: 0 !important;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	display: grid;
	place-items: center;
	padding: 0;
	border: 0;

	&:hover,
	&:focus {
		background: #eee;
	}

	&.btn i {
		margin: 0 !important;
		opacity: 1 !important;
	}
`;

const Input = styled(Form.Control)`
	border-radius: 0;
	padding: 0.5rem;
	border: 1px dashed #eee;
	box-shadow: none;

	&:focus {
		outline: 0;
		border: 1px solid #ddd;
		box-shadow: 0 0 0 3px #fafafa;
	}
`;

const EditDescription = ({ siteId, business_vertical, page, fetchData }) => {
	// Props
	const { uuid, seo } = { ...page };
	const { title, description } = { ...seo };

	// Hooks
	const { setMessage } = useMessage();

	// State
	const [showEdit, setShowEdit] = useState(false);
	const [newTitle, setNewTitle] = useState(title || '');
	const [progressColor, setProgressColor] = useState('');

	// Effects
	useEffect(() => {
		const textLength = newTitle.length;
		if (textLength === 0) {
			setProgressColor('danger');
		} else {
			setProgressColor('warning');
		}
		if (textLength >= 50 && textLength <= 160) {
			setProgressColor('success');
		}
	}, [newTitle]);

	// Form handler
	const handleSubmit = async e => {
		e.preventDefault();

		// Generate meta body
		const newDescription = description || '';
		const body = { uuid, title: newTitle, description: newDescription };
		try {
			await fetcher(`/api/v1/sites/meta/${business_vertical}/${siteId}`, { method: 'POST', body: JSON.stringify(body) });
			setMessage({ variant: 'success', text: 'Saved meta title succesfully.' });
		} catch (error) {
			setMessage({ variant: 'danger', text: 'Could not save meta title.' });
		} finally {
			setShowEdit(false);
			await fetchData();
		}
	};

	return !showEdit ? (
		<div className='hover-edit' onClick={() => setShowEdit(true)}>
			{MetaDescription(newTitle)}
		</div>
	) : (
		<>
			<Form className='mt-1 mb-2' onSubmit={e => handleSubmit(e)}>
				<Row>
					<Input as='textarea' rows={3} value={newTitle} onChange={e => setNewTitle(e.target.value)} />
					<Buttons>
						<CustomButton type='submit'>
							<i className='fas fa-check text-success'></i>
						</CustomButton>
						<CustomButton type='button' onClick={() => setShowEdit(false)}>
							<i className='fas fa-times text-danger'></i>
						</CustomButton>
					</Buttons>
				</Row>
			</Form>
			<ProgressBar variant={progressColor} now={newTitle.length > 0 ? newTitle.length : 160} max={160} className='mb-1' />
			<small className='text-secondary'>
				Length: <span className='text-dark'>{newTitle.length} characters</span>
			</small>
		</>
	);
};

export default EditDescription;
