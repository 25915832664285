import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Table = styled.div`
	background: white;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
	overflow: auto;
	display: grid;
	border-radius: 0.5rem;
	justify-self: stretch;
`;

const Header = styled.div`
	background: var(--bs-dark);
	color: white;
	font-weight: bold;
	display: grid;
	grid-template-columns: ${({ $cols }) => $cols};
	align-items: center;
`;

const Row = styled.div`
	display: grid;
	grid-template-columns: ${({ $cols }) => $cols};
	align-items: center;
	border-bottom: 1px solid var(--border);
	text-decoration: none !important;
	color: inherit;
	transition: 0.2s;

	&:last-of-type {
		border: 0;
	}
`;

const RowLink = styled(Link)`
	display: grid;
	grid-template-columns: ${({ $cols }) => $cols};
	align-items: center;
	border-bottom: 1px solid var(--border);
	text-decoration: none !important;
	color: inherit;
	transition: 0.2s;

	&:last-of-type {
		border: 0;
	}

	&:hover,
	&:focus-visible {
		z-index: 1;
		box-shadow: rgb(0 0 0 / 18%) 0px 0px 3rem;
	}
`;

const Cell = styled.div`
	padding: 0.75rem 1rem;
	overflow: hidden;
	white-space: ${({ $wrap }) => ($wrap ? 'auto' : 'nowrap')};
	text-overflow: ellipsis;

	& > a {
		text-decoration: none;
		color: inherit;
	}
`;

const NoDataComponent = styled.div`
	padding: 2rem;
	text-align: center;
`;

const NoData = () => {
	return <NoDataComponent>No data to display.</NoDataComponent>;
};

export { Table, Header, Row, RowLink, Cell, NoData };
